import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Controller } from "react-hook-form";
import { twMerge } from "tailwind-merge";
import InputGroup from "../../../UI/Inputs/InputGroup";
import { getAllUsersForShareRequest } from "../../../../requests/users";
import { fullName } from "../../../../utils/fullName";
import { displayError } from "../../../../utils/displayError";
import MultiSelect from "../../../UI/Inputs/MultiSelect";
import { checkAllowSharing } from "../../../../utils/checkAllowSharing";
import SearchUser from "../../../UI/SearchUser";
import { hoverLink } from "../../../../utils/hoverLink";

export default function Instructors({ student, isAdmin, school, methods }) {
  const schoolId = school?.id;
  const [instructorsToShare, setInstructorsToShare] = useState([]);
  const [editInstructor, setEditInstructor] = useState(false);
  const headSchoolAdmin = school?.head_school_admin;

  const defaultStudent = student || {
    instructor_name:
      headSchoolAdmin?.first_name + " " + headSchoolAdmin?.last_name,
    instructor_id: headSchoolAdmin?.id,
    school: school,
  };

  const defaultSchoolAndInstructor = schoolId
    ? student?.instructor_id || `${schoolId}#${headSchoolAdmin?.id}`
    : `${student?.school_id}#${student?.instructor_id}`;

  const getInstructorsForShare = async () => {
    try {
      const res = await getAllUsersForShareRequest();
      if (res.data) {
        const instructors = res.data.map((user) => ({
          id: user.id,
          name: fullName(user.first_name, "", user.last_name),
        }));
        setInstructorsToShare(instructors);
      }
    } catch (e) {
      displayError(e);
    }
  };

  useEffect(() => {
    if (isAdmin || checkAllowSharing(student?.school_id)) {
      getInstructorsForShare();
    }
  }, [schoolId]);

  useEffect(() => {
    if (student?.instructor_id) {
      setEditInstructor(false);
    }
  }, [student]);

  useEffect(() => {
    if (schoolId && defaultSchoolAndInstructor) {
      methods.setValue("school_and_instructor", {
        value: defaultSchoolAndInstructor,
      });
    }
  }, [schoolId, defaultSchoolAndInstructor]);

  return (
    <>
      {isAdmin && (
        <>
          <h2 className="mb-2 text-2xl">Instructor</h2>

          <div className="flex items-center gap-2 mb-6 justify-between">
            {editInstructor ? (
              <Controller
                name="school_and_instructor"
                control={methods?.control}
                render={({ field }) => (
                  <SearchUser
                    field={field}
                    userType="Instructor"
                    reassign
                    light
                    filterByschoolId={schoolId}
                    highlightSchoolId={student?.school_id}
                  />
                )}
              />
            ) : (
              <div>
                <p>
                  <Link
                    to={`/instructors/${defaultStudent?.instructor_id}/overview`}
                    className={hoverLink}
                  >
                    {defaultStudent?.instructor_name}
                  </Link>
                </p>
                <p className="text-xs">
                  <Link
                    to={`/schools/${defaultStudent?.school?.id}/overview`}
                    className={hoverLink}
                  >
                    (#{defaultStudent?.school?.school_code}){" "}
                    {defaultStudent?.school?.church_name}
                  </Link>
                </p>
              </div>
            )}

            <button
              type="button"
              onClick={() => {
                setEditInstructor(!editInstructor);
                methods.setValue(
                  "school_and_instructor",
                  school
                    ? {
                        value: defaultSchoolAndInstructor,
                      }
                    : null
                );
              }}
              className={twMerge(
                "text-sm text-white rounded px-2 py-2 hover:bg-gray-600 transition-colors duration-200",
                editInstructor
                  ? "bg-gray-500 hover:bg-gray-600"
                  : "bg-secondary hover:bg-secondary-600"
              )}
            >
              {editInstructor ? "Cancel" : "Change Instructor"}
            </button>
          </div>
        </>
      )}

      {student &&
        instructorsToShare.length > 0 &&
        checkAllowSharing(student?.school_id) && (
          <>
            <h2 className="mb-2 text-2xl">Shared With</h2>
            <InputGroup>
              <MultiSelect
                id="shared_with"
                placeholder="Add Instructors"
                options={instructorsToShare}
                defaultSelected={[]}
              />
            </InputGroup>
          </>
        )}
    </>
  );
}
