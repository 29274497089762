import { logout } from "../requests/auth";
import { removeToken } from "./auth";
import { ToastNotification } from "../components/UI/ToastNotification";

const handleLogout = async () => {
  try {
    await logout();
  } catch (e) {
    const errorMessage = e.response?.data?.error;
    ToastNotification("error", errorMessage);
  } finally {
    removeToken();
    window.location.replace("/login");
  }
};

export default handleLogout;
