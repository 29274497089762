import React, { useEffect, useState } from "react";
import Select from "../../../UI/Inputs/Select/Select";
import SelectItem from "../../../UI/Inputs/Select/SelectItem";
import Input from "../../../UI/Inputs/Input";
import Checkbox from "../../../UI/Inputs/Checkbox";
import DatePickerInput from "../../../UI/Inputs/DatePickerInput";
import MultiSelect from "../../../UI/Inputs/MultiSelect";
import { ToastNotification } from "../../../UI/ToastNotification";
import { getLookupsByType } from "../../../../requests/lookups";
import { getAvailableSourceCodes } from "../../../../requests/availableSourceCodes";
import { getAvailableAdvertisingMethods } from "../../../../requests/availableAdvertisingMethods";
import InputGroup from "../../../UI/Inputs/InputGroup";

export default function DemographicsForm({ student, isAdmin }) {
  const [genders, setGenders] = useState([]);
  const maritalStatus = ["Married", "Single"];
  const [advertisingMethods, setAdvertisingMethods] = useState([]);
  const [sources, setSources] = useState([]);

  const getGenders = async () => {
    try {
      const res = await getLookupsByType({ lookupType: 1 });

      if (res.data) {
        setGenders(res.data);
      }
    } catch (e) {
      ToastNotification("error", "Error fetching genders.");
    }
  };

  const fetchAdvertisingMethods = () => {
    getAvailableAdvertisingMethods().then((res) => {
      setAdvertisingMethods(res.data.map((tag) => tag.name));
    });
  };

  const fetchSourceCodes = () => {
    getAvailableSourceCodes().then((res) => {
      setSources(res.data);
    });
  };

  useEffect(() => {
    fetchAdvertisingMethods();
    fetchSourceCodes();
    getGenders();
  }, []);

  return (
    <div>
      <h2 className="mb-6 text-2xl">Demographics</h2>

      <InputGroup>
        <Checkbox
          disabled={!isAdmin}
          id="baptized"
          defaultChecked={student?.baptized}
          label="Is baptized"
        />
      </InputGroup>

      {genders.length > 0 && (
        <InputGroup>
          <Select
            disabled={!isAdmin}
            id="gender"
            label="Gender"
            defaultValue={student?.gender}
          >
            <SelectItem value="" disabled selected>
              Select a gender
            </SelectItem>
            {genders.map((gender) => (
              <SelectItem
                key={gender.id}
                value={gender.name}
                selected={student?.gender === gender.name}
              >
                {gender.name}
              </SelectItem>
            ))}
          </Select>
        </InputGroup>
      )}

      <InputGroup>
        <Select
          disabled={!isAdmin}
          id="married"
          label="Marital Status"
          defaultValue={student?.married}
        >
          <SelectItem value="" disabled selected>
            Select marital status
          </SelectItem>
          {maritalStatus.map((option) => (
            <SelectItem
              key={option}
              value={option}
              selected={student?.married === "Married"}
            >
              {option}
            </SelectItem>
          ))}
        </Select>
      </InputGroup>

      <InputGroup>
        <DatePickerInput
          disabled={!isAdmin}
          id="birthdate"
          label="Birthdate"
          selected={student?.birthdate}
        />
      </InputGroup>

      <InputGroup col>
        <Input
          disabled={!isAdmin}
          id="denomination"
          defaultValue={student?.denomination}
          placeholder="Denomination"
          label="Denomination"
        />
      </InputGroup>

      <InputGroup>
        <MultiSelect
          disabled={!isAdmin}
          id="advertising_method_list"
          placeholder="Advertising Method"
          options={advertisingMethods}
          defaultSelected={student?.advertising_method_list || []}
        />
      </InputGroup>

      <InputGroup>
        <Select
          disabled={!isAdmin || sources.length === 0}
          id="cp_source_code"
          classNames="w-full"
          defaultValue={student?.cp_source_code}
        >
          <SelectItem value="">-- No source of contact --</SelectItem>
          {sources.map((source) => (
            <SelectItem
              key={source.code}
              value={source.code}
              selected={student?.cp_source_code === source.code}
            >
              {source.code}: {source.description}
            </SelectItem>
          ))}
        </Select>
      </InputGroup>
    </div>
  );
}
