import React, { useEffect, useState, useCallback } from "react";
import TableCell from "../../UI/Table/TableCell";
import Input from "../../UI/Inputs/Input";
import Select from "../../UI/Inputs/Select/Select";
import SelectItem from "../../UI/Inputs/Select/SelectItem";
import {
  getAllSchoolCourses,
  getAllSchoolInstructors,
  getAllSchoolSeminars,
} from "../../../requests/schools";
import { displayError } from "../../../utils/displayError";
import Checkbox from "../../UI/Inputs/Checkbox";
import DatePickerInput from "../../UI/Inputs/DatePickerInput";

export default function CsvStudentsRow({
  index,
  student,
  schools,
  methods,
  handleRemovedRows,
  removedRows,
  countries,
  states,
  genders,
  hasColumn,
  importSchoolId,
  importSeriesId,
  focusedCell,
  importSchoolInstructors,
}) {
  const { watch } = methods;
  const [selectedSchool, setSelectedSchool] = useState();
  const [instructors, setInstructors] = useState(importSchoolInstructors);
  const denominations = ["Mr.", "Mrs.", "Miss", "Ms."];
  const selectedCountry = watch(
    `csv_students.${index}.country`,
    student?.country
  );
  const [seminars, setSeminars] = useState([]);
  const [courses, setCourses] = useState([]);
  const [isRowChecked, setIsRowChecked] = useState(true);
  const [filteredStates, setFilteredStates] = useState([]);
  const schoolId = importSchoolId || selectedSchool?.id;

  const getInstructors = useCallback(async () => {
    try {
      const res = await getAllSchoolInstructors({ school_id: schoolId });
      if (res.data) setInstructors(res.data);
    } catch (e) {
      displayError(e);
    }
  }, [schoolId]);

  const getSeminars = useCallback(async () => {
    try {
      const res = await getAllSchoolSeminars({ school_id: schoolId });
      if (res.data) setSeminars(res.data);
    } catch (e) {
      displayError(e);
    }
  }, [schoolId]);

  const getCourses = useCallback(async () => {
    const res = await getAllSchoolCourses({ school_id: schoolId });
    if (res.data) setCourses(res.data);
  }, [schoolId]);

  const filterStatesByCountry = async () => {
    try {
      const countryId = countries.find(
        (country) => country.name === selectedCountry
      )?.id;

      setFilteredStates(
        states.filter((state) => state.country_id === countryId)
      );
    } catch (e) {
      displayError(e);
    }
  };

  const handleSchoolChange = (value) => {
    setSelectedSchool(schools.find((s) => s.id === value));
  };

  const handleInstructorChange = async (value) => {
    let instructor = instructors.find((i) => i.id === value);

    if (instructor) {
      methods.setValue(`csv_students.${index}.instructor_id`, value);

      methods.setValue(
        `csv_students.${index}.instructor_name`,
        instructor.first_name + " " + instructor.last_name
      );
    }
  };

  const handleSeriesChange = async (value) => {
    let series =
      seminars.find((s) => s.id === value) ||
      courses.find((c) => c.id === value);

    if (series) {
      methods.setValue(`csv_students.${index}.series_name`, series.name);
    }
  };

  const importSchool = schools?.find((s) => s.id === importSchoolId);

  useEffect(() => {
    methods.setValue(`csv_students.${index}.index`, index);
  }, []);

  useEffect(() => {
    if (schools) {
      const currentSchoolId =
        watch(`csv_students.${index}.school_id`) || student.school_id;
      setSelectedSchool(schools.find((s) => s.id === currentSchoolId));
    }
  }, [schools]);

  useEffect(() => {
    if (importSchoolId) {
      methods.setValue(`csv_students.${index}.school_id`, importSchoolId);
      setSelectedSchool(importSchool);
    }
  }, [importSchoolId]);

  useEffect(() => {
    if (importSeriesId) {
      methods.setValue(`csv_students.${index}.series_id`, importSeriesId);
    }
  }, [importSeriesId]);

  useEffect(() => {
    if (countries.length > 0) {
      filterStatesByCountry();
    }
  }, [selectedCountry, countries]);

  useEffect(() => {
    if (selectedSchool) {
      methods.setValue(
        `csv_students.${index}.school_name`,
        selectedSchool.church_name
      );
      methods.setValue(
        `csv_students.${index}.school_code`,
        selectedSchool.school_code
      );

      if (!importSchoolInstructors) {
        getInstructors();
      }

      getSeminars();
      getCourses();
    }
  }, [selectedSchool]);

  // There are 3 cases to pre-select the Instructor while changing School:
  // 1. The instructor is the selected one directly in dropdown;
  // 2. The instructor is the CSV column match;
  // 3. The instructor is the head school admin;
  useEffect(() => {
    if (instructors?.length) {
      const currentInstructorId = watch(`csv_students.${index}.instructor_id`);

      if (instructors.find((i) => i.id === currentInstructorId)) {
        handleInstructorChange(currentInstructorId);
      } else if (instructors.find((i) => i.id === student.instructor_id)) {
        handleInstructorChange(student.instructor_id);
      } else if (selectedSchool?.head_school_admin_id) {
        handleInstructorChange(selectedSchool.head_school_admin_id);
      } else {
        methods.setValue(`csv_students.${index}.instructor_id`, "");
        methods.setValue(`csv_students.${index}.instructor_name`, "");
      }
    } else if (instructors) {
      methods.setValue(`csv_students.${index}.instructor_id`, "");
      methods.setValue(`csv_students.${index}.instructor_name`, "");
    }
  }, [instructors, selectedSchool]);

  return (
    <tr key={index} id={`row-${index}`}>
      <TableCell classNames="flex items-center gap-2 h-[63px]">
        {index + 1}.
        <Checkbox
          id={`checkbox-${index}`}
          defaultChecked={!removedRows.includes(index)}
          onClick={(event) => {
            setIsRowChecked(event.target.checked);
            handleRemovedRows(event, index);
          }}
        />
      </TableCell>
      <TableCell classNames="pb-5">
        <Select
          id={`csv_students.${index}.title`}
          classNames="w-full"
          defaultValue={student.title}
          disabled={!hasColumn("title")}
          autoFocus={focusedCell === "title"}
        >
          <SelectItem value="">Title</SelectItem>
          {denominations.map((option) => (
            <SelectItem
              key={option}
              value={option}
              selected={student.title === option}
            >
              {option}
            </SelectItem>
          ))}
        </Select>
      </TableCell>
      <TableCell classNames="pb-5">
        <Input
          isRequired={isRowChecked}
          id={`csv_students.${index}.first_name`}
          defaultValue={student.first_name}
          index={index}
          disabled={!hasColumn("first_name")}
          autoFocus={focusedCell === "first_name"}
        />
      </TableCell>
      <TableCell classNames="pb-5">
        <Input
          id={`csv_students.${index}.middle_name`}
          defaultValue={student.middle_name}
          disabled={!hasColumn("middle_name")}
          autoFocus={focusedCell === "middle_name"}
        />
      </TableCell>
      <TableCell classNames="pb-5">
        <Input
          isRequired={isRowChecked}
          id={`csv_students.${index}.last_name`}
          defaultValue={student.last_name}
          index={index}
          disabled={!hasColumn("last_name")}
          autoFocus={focusedCell === "last_name"}
        />
      </TableCell>
      <TableCell classNames="pb-5">
        {!importSchoolId ? (
          <Select
            id={`csv_students.${index}.school_id`}
            isRequired={isRowChecked}
            index={index}
            disabled={!schools || !hasColumn("school_id")}
            onChange={(e) => handleSchoolChange(e.target.value)}
            autoFocus={focusedCell === "school_id"}
          >
            <SelectItem value="" disabled>
              {schools ? "Select a school" : "Loading..."}
            </SelectItem>
            {schools?.map((school) => (
              <SelectItem key={school.id} value={school.id}>
                (#{school.school_code}) {school.church_name}
              </SelectItem>
            ))}
          </Select>
        ) : (
          <span className="whitespace-nowrap">-- Selected --</span>
        )}
      </TableCell>
      <TableCell classNames="pb-5">
        <Select
          id={`csv_students.${index}.instructor_id`}
          isRequired={isRowChecked}
          index={index}
          disabled={!hasColumn("instructor_id")}
          autoFocus={focusedCell === "instructor_id"}
          onChange={(e) => handleInstructorChange(e.target.value)}
        >
          <SelectItem value="" disabled>
            Select an instructor
          </SelectItem>
          {instructors?.map((instructor) => (
            <SelectItem key={instructor.id} value={instructor.id}>
              {instructor.first_name} {instructor.last_name}
              {instructor.id === selectedSchool?.head_school_admin_id &&
                " (Head School Admin)"}
            </SelectItem>
          ))}
        </Select>
      </TableCell>
      <TableCell classNames="pb-5">
        {!importSeriesId ? (
          <Select
            id={`csv_students.${index}.series_id`}
            defaultValue={student.series_id}
            disabled={!hasColumn("series_id")}
            autoFocus={focusedCell === "series_id"}
            onChange={(e) => handleSeriesChange(e.target.value)}
          >
            <SelectItem value="">Select a course/seminar</SelectItem>
            {seminars.length > 0 && (
              <optgroup label="Seminars" key="seminars">
                {seminars.map((seminar) => (
                  <SelectItem
                    key={seminar.id}
                    value={seminar.id}
                    selected={
                      methods.watch(`csv_students.${index}.series_id`) ===
                      seminar.id
                    }
                  >
                    {seminar.name}
                  </SelectItem>
                ))}
              </optgroup>
            )}
            {courses.length > 0 && (
              <optgroup label="Courses" key="courses">
                {courses.map((course) => (
                  <SelectItem
                    key={course.id}
                    value={course.id}
                    selected={
                      methods.watch(`csv_students.${index}.series_id`) ===
                      course.id
                    }
                  >
                    {course.name}
                  </SelectItem>
                ))}
              </optgroup>
            )}
          </Select>
        ) : (
          <span className="whitespace-nowrap">
            <span>-- Selected --</span>
          </span>
        )}
      </TableCell>
      <TableCell classNames="pb-5">
        <Input
          id={`csv_students.${index}.registration_number`}
          defaultValue={student.registration_number}
          disabled={!hasColumn("registration_number")}
          autoFocus={focusedCell === "registration_number"}
        />
      </TableCell>
      <TableCell classNames="pb-5">
        <Input
          id={`csv_students.${index}.referral`}
          defaultValue={student.referral}
          disabled={!hasColumn("referral")}
          autoFocus={focusedCell === "referral"}
        />
      </TableCell>
      <TableCell classNames="pb-5">
        <Input
          id={`csv_students.${index}.address`}
          defaultValue={student.address}
          disabled={!hasColumn("address")}
          autoFocus={focusedCell === "address"}
        />
      </TableCell>
      <TableCell classNames="pb-5">
        <Input
          id={`csv_students.${index}.address_2`}
          defaultValue={student.address_2}
          disabled={!hasColumn("address_2")}
          autoFocus={focusedCell === "address_2"}
        />
      </TableCell>
      <TableCell classNames="pb-5">
        <Input
          id={`csv_students.${index}.city`}
          defaultValue={student.city}
          disabled={!hasColumn("city")}
          autoFocus={focusedCell === "city"}
        />
      </TableCell>
      <TableCell classNames="pb-5">
        <Select
          id={`csv_students.${index}.state`}
          defaultValue={student.state}
          disabled={!hasColumn("state")}
          autoFocus={focusedCell === "state"}
        >
          <SelectItem value="">
            {watch(`csv_students.${index}.country`)
              ? "-- Select state --"
              : "-- Select country first --"}
          </SelectItem>
          {watch(`csv_students.${index}.country`) &&
            filteredStates.map((state) => (
              <SelectItem
                key={state.id}
                value={state.abbreviation}
                selected={student.state === state.abbreviation}
              >
                {state.name}
              </SelectItem>
            ))}
        </Select>
      </TableCell>
      <TableCell classNames="pb-5">
        <Input
          id={`csv_students.${index}.zip_code`}
          defaultValue={student.zip_code}
          disabled={!hasColumn("zip_code")}
          autoFocus={focusedCell === "zip_code"}
        />
      </TableCell>
      <TableCell classNames="pb-5">
        <Select
          id={`csv_students.${index}.country`}
          defaultValue={student?.country}
          disabled={!hasColumn("country")}
          autoFocus={focusedCell === "country"}
        >
          <SelectItem value="">-- Select country --</SelectItem>
          {countries.map((country) => (
            <SelectItem
              key={country.id}
              value={country.name}
              selected={student.country === country.name}
            >
              {country.name}
            </SelectItem>
          ))}
        </Select>
      </TableCell>
      <TableCell classNames="pb-5">
        <Select
          id={`csv_students.${index}.gender`}
          defaultValue={student?.gender}
          disabled={!hasColumn("gender")}
          autoFocus={focusedCell === "gender"}
        >
          <SelectItem value="">-- Select gender --</SelectItem>
          {genders.map((gender) => (
            <SelectItem
              key={gender.id}
              value={gender.name}
              selected={student?.gender === gender.name}
            >
              {gender.name}
            </SelectItem>
          ))}
        </Select>
      </TableCell>
      <TableCell classNames="pb-5 text-center">
        <Checkbox
          id={`csv_students.${index}.married`}
          defaultChecked={student?.married}
          disabled={!hasColumn("married")}
          autoFocus={focusedCell === "married"}
          classNames="inline-block"
        />
      </TableCell>
      <TableCell classNames="pb-5 [&_.react-datepicker-popper]:z-[1000]">
        <DatePickerInput
          id={`csv_students.${index}.birthdate`}
          selected={student.birthdate}
          disabled={!hasColumn("birthdate")}
          autoFocus={focusedCell === "birthdate"}
        />
      </TableCell>
      <TableCell classNames="pb-5">
        <Input
          id={`csv_students.${index}.denomination`}
          defaultValue={student.denomination}
          disabled={!hasColumn("denomination")}
          autoFocus={focusedCell === "denomination"}
        />
      </TableCell>
      <TableCell classNames="pb-5 text-center">
        <Checkbox
          id={`csv_students.${index}.baptized`}
          defaultChecked={student.baptized}
          disabled={!hasColumn("baptized")}
          autoFocus={focusedCell === "baptized"}
          classNames="inline-block"
        />
      </TableCell>
      <TableCell classNames="pb-5">
        <Input
          id={`csv_students.${index}.phone`}
          defaultValue={student.phone}
          disabled={!hasColumn("phone")}
          autoFocus={focusedCell === "phone"}
        />
      </TableCell>
      <TableCell classNames="pb-5 text-center">
        <Checkbox
          id={`csv_students.${index}.phone_is_sms`}
          defaultChecked={student.phone_is_sms}
          disabled={!hasColumn("phone_is_sms")}
          autoFocus={focusedCell === "phone_is_sms"}
          classNames="inline-block"
        />
      </TableCell>
      <TableCell classNames="pb-5">
        <Input
          id={`csv_students.${index}.phone_2`}
          defaultValue={student.phone_2}
          disabled={!hasColumn("phone_2")}
          autoFocus={focusedCell === "phone_2"}
        />
      </TableCell>
      <TableCell classNames="pb-5 text-center">
        <Checkbox
          id={`csv_students.${index}.phone_2_is_sms`}
          defaultChecked={student.phone_2_is_sms}
          disabled={!hasColumn("phone_2_is_sms")}
          autoFocus={focusedCell === "phone_2_is_sms"}
          classNames="inline-block"
        />
      </TableCell>
      <TableCell classNames="pb-5">
        <Input
          id={`csv_students.${index}.email`}
          defaultValue={student.email}
          disabled={!hasColumn("email")}
          autoFocus={focusedCell === "email"}
        />
      </TableCell>
      <TableCell classNames="pb-5">
        <Input
          id={`csv_students.${index}.social_media`}
          defaultValue={student.social_media}
          disabled={!hasColumn("social_media")}
          autoFocus={focusedCell === "social_media"}
        />
      </TableCell>
    </tr>
  );
}
