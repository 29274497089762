import React, { useState, useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { fullName } from "../../utils/fullName";
import Table from "../UI/Table/Table";
import TableHeader from "../UI/Table/TableHeader";
import TableCell from "../UI/Table/TableCell";
import Button from "../UI/Button";
import EditOnHover from "../UI/EditOnHover";
import StudentEnrollmentsCell from "../UI/StudentEnrollmentsCell";
import { hoverLink } from "../../utils/hoverLink";
import dayjs from "dayjs";
import { addressMultiLine } from "../../utils/fullAddress";
import { highlightText } from "../../utils/highlightText";
import { checkPermission } from "../../utils/checkPermission";

export default function StudentsTable({
  students,
  loading,
  selectedStudents,
  setSelectedStudents,
  setPrintTicketModal,
}) {
  const isAdmin = checkPermission(["school_admin", "conference_admin"]);
  const isGlobalAdmin = checkPermission(["global_admin"]);
  const [searchParams] = useSearchParams();
  const query = searchParams.get("query") || "";
  const fullQuery = searchParams.get("fullQuery") || "";
  const searchQuery = query || fullQuery;
  const [selected, setSelected] = useState(selectedStudents);

  const handleStudentSelection = (studentId) => {
    setSelected((prev) => {
      if (prev.includes(studentId)) {
        return prev.filter((id) => id !== studentId);
      } else {
        return [...prev, studentId];
      }
    });
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelected(students.map((student) => student.id));
    } else {
      setSelected([]);
    }
  };

  useEffect(() => {
    setSelectedStudents(selected);
  }, [selected]);

  useEffect(() => {
    setSelected(selectedStudents);
  }, [selectedStudents]);

  return (
    <Table>
      <thead>
        <tr>
          <TableHeader classNames="w-10 pb-2">
            <div className="flex align-center items-start h-full pr-2">
              <input
                type="checkbox"
                checked={selected.length === students.length}
                onChange={handleSelectAll}
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              />
            </div>
          </TableHeader>
          <TableHeader
            isSortable
            isMultiSort
            multiSortOptions={[
              { name: "First", value: "first_name" },
              { name: "Last", value: "last_name" },
            ]}
            classNames="pb-2"
          >
            STUDENT
          </TableHeader>
          <TableHeader
            isSortable
            isMultiSort
            sortBy="enrollments"
            classNames="pb-2"
            multiSortOptions={[
              {
                name: "Latest Lesson Sent",
                value: "cached_latest_sent_date",
              },
              {
                name: "Registration number",
                value: "cached_registration_numbers",
              },
            ]}
          >
            ENROLLMENTS
          </TableHeader>
          <TableHeader
            isSortable
            isMultiSort
            multiSortOptions={[
              { name: "City", value: "city" },
              { name: "State", value: "state" },
              { name: "Zip", value: "zip_code" },
              { name: "Country", value: "country" },
            ]}
          >
            ADDRESS
          </TableHeader>
          <TableHeader isSortable sortBy="created_at" classNames="pb-2">
            CREATED
          </TableHeader>
          <TableHeader
            isSortable
            sortBy="instructor_first_name"
            classNames="pb-2"
          >
            INSTRUCTOR
          </TableHeader>
          <TableHeader isSortable sortBy="school_church_name" classNames="pb-2">
            SCHOOL
          </TableHeader>
          <TableHeader classNames="pb-2"></TableHeader>
        </tr>
      </thead>
      <tbody>
        {students.length > 0 &&
          students.map((student) => (
            <tr key={student.id} className={loading ? "opacity-25" : undefined}>
              <TableCell classNames="w-10">
                <div className="flex items-start h-full pr-2">
                  <input
                    type="checkbox"
                    checked={selected.includes(student.id)}
                    onChange={() => handleStudentSelection(student.id)}
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  />
                </div>
              </TableCell>
              <TableCell>
                <EditOnHover to={`/students/${student.id}/profile`}>
                  <Link
                    className={`${hoverLink}`}
                    to={`/students/${student.id}/overview`}
                  >
                    {highlightText(
                      fullName(
                        student.first_name,
                        student.middle_name,
                        student.last_name
                      ),
                      searchQuery
                    )}
                  </Link>
                </EditOnHover>
              </TableCell>
              <TableCell classNames="text-sm min-w-[350px]">
                <StudentEnrollmentsCell
                  student={student}
                  searchQuery={searchQuery}
                />
              </TableCell>
              <TableCell classNames="whitespace-nowrap">
                {addressMultiLine(
                  {
                    address: student.address,
                    address_2: student.address_2,
                    city: student.city,
                    state: student.state,
                    country:
                      student.country !== "United States"
                        ? student.country
                        : undefined,
                    zip_code: student.zip_code,
                  },
                  { highlight: searchParams.get("sortBy"), query: searchQuery }
                )}
              </TableCell>
              <TableCell>
                {dayjs(student.created_at).format("MM/DD/YYYY")}
              </TableCell>
              <TableCell>
                {isGlobalAdmin || isAdmin ? (
                  <EditOnHover
                    to={`/instructors/${student.instructor_id}/profile`}
                  >
                    <Link
                      className={hoverLink}
                      to={`/instructors/${student.instructor_id}/overview`}
                    >
                      {student.instructor_name}
                    </Link>
                  </EditOnHover>
                ) : (
                  <span>{student.instructor_name}</span>
                )}
                {student.student_shares.length > 0 && (
                  <ul className="ml-2 text-xs text-yellow-500">
                    {student.student_shares.map((share) => (
                      <li key={share.id}>
                        {isGlobalAdmin || isAdmin ? (
                          <Link
                            className={hoverLink}
                            to={`/instructors/${share.user_id}/overview`}
                          >
                            {share.user_name}
                          </Link>
                        ) : (
                          <span>{share.user_name}</span>
                        )}
                      </li>
                    ))}
                  </ul>
                )}
              </TableCell>
              <TableCell>
                {isGlobalAdmin || isAdmin ? (
                  <Link
                    className={hoverLink}
                    to={`/schools/${student.school.id}/overview`}
                  >
                    {highlightText(
                      `(#${student.school.school_code}) ${student.school.church_name}`,
                      searchQuery
                    )}
                  </Link>
                ) : (
                  <span>
                    {highlightText(
                      `(#${student.school.school_code}) ${student.school.church_name}`,
                      searchQuery
                    )}
                  </span>
                )}
              </TableCell>
              <TableCell>
                <Button
                  style={{
                    display:
                      "none" /* TODO: Remove this when functionality is added */,
                  }}
                  onClick={() =>
                    setPrintTicketModal({
                      open: true,
                      student: student,
                    })
                  }
                  variant="primary-outline"
                >
                  Print Ticket
                </Button>
              </TableCell>
            </tr>
          ))}
      </tbody>
    </Table>
  );
}
