import React, { useEffect, useRef, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import ActionMenuContainer from "../../ActionMenu/ActionMenuContainer";
import Button from "../../UI/Button";
import { ToastNotification } from "../../UI/ToastNotification";
import { displayError } from "../../../utils/displayError";
import TableHeader from "../../UI/Table/TableHeader";
import Table from "../../UI/Table/Table";
import PageLoader from "../../UI/PageLoader";
import { updateStudentImportRequest } from "../../../requests/studentImports";
import {
  getAllSchools,
  getAllSchoolInstructors,
} from "../../../requests/schools";
import Checkbox from "../../UI/Inputs/Checkbox";
import { getLookupsByType } from "../../../requests/lookups";
import { getAllCountries } from "../../../requests/countries";
import { getAllStates } from "../../../requests/states";
import CsvAutoAssign from "./CsvAutoAssign";
import CsvReadOnlyRow from "./CsvReadOnlyRow";
import CsvStudentsRow from "./CsvStudentsRow";
import CsvResults from "./CsvResults";

const availableColumns = [
  "address",
  "address_2",
  "baptized",
  "birthdate",
  "city",
  "country",
  "denomination",
  "email",
  "first_name",
  "gender",
  "index",
  "instructor_id",
  "instructor_name",
  "last_name",
  "married",
  "middle_name",
  "phone",
  "phone_2",
  "phone_2_is_sms",
  "phone_is_sms",
  "referral",
  "registration_number",
  "school_code",
  "school_id",
  "school_name",
  "series_id",
  "social_media",
  "state",
  "title",
  "zip_code",
];

export default function CsvStudents({
  closeModal,
  setRefreshStudents,
  studentImport,
  setStudentImport,
}) {
  const importResults = studentImport?.results;
  const csvStudents = studentImport?.parsed_csv;
  const methods = useForm({
    defaultValues: {
      csv_students: importResults,
      school_id: studentImport?.school_id,
      import_series_id: studentImport?.series_id,
    },
  });
  const { fields, append } = useFieldArray({
    control: methods.control,
    name: "csv_students",
  });
  const appendCalled = useRef(false);
  const [schools, setSchools] = useState();
  const [removedRows, setRemovedRows] = useState([]);
  const [columns, setColumns] = useState(
    studentImport?.results ? studentImport.columns : availableColumns
  );
  const [countries, setCountries] = useState([]);
  const [genders, setGenders] = useState([]);
  const [states, setStates] = useState([]);
  const [showResults, setShowResults] = useState(Boolean(importResults));
  const selectedSchoolId = methods.watch("school_id");
  const selectedSeriesId = methods.watch("import_series_id");
  const [selectedRow, setSelectedRow] = useState(null);
  const [focusedCell, setFocusedCell] = useState(null);
  const [loading, setLoading] = useState(true);
  const [instructors, setInstructors] = useState();
  const [schoolSearchFocused, setSchoolSearchFocused] = useState(false);

  const handleRemovedRows = async (event, value) => {
    if (!event.target.checked) {
      setRemovedRows([...removedRows, value]);
    } else {
      setRemovedRows(removedRows.filter((item) => item !== value));
    }
  };

  const handleRemovedColumns = async (event, value) => {
    if (event.target.checked) {
      setColumns(new Array(...columns, value));
    } else {
      setColumns(columns.filter((item) => item !== value));
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);
    setFocusedCell(null);
    setSelectedRow(null);

    // filter removed rows
    const dataCsv = data.csv_students.filter(
      (s) => !removedRows.includes(s.index)
    );

    if (dataCsv.length === 0) {
      ToastNotification("error", "No students selected.");
      return;
    }

    try {
      const res = await updateStudentImportRequest(studentImport.id, {
        student_import: {
          results: dataCsv,
          columns: columns,
          school_id: data.school_id,
          series_id: data.import_series_id,
        },
      });

      if (res.data) {
        ToastNotification("success", "Import preview updated.");
        setRemovedRows([]);
        setStudentImport(res.data.student_import);
        methods.setValue("csv_students", res.data.student_import.results);
        setShowResults(true);
      }
    } catch (e) {
      displayError(e);
    } finally {
      setLoading(false);
    }
  };

  const getSchools = async () => {
    const res = await getAllSchools({ params: { pagination: false } });
    if (res.data) {
      setSchools(res.data.schools);
    }
  };

  const getCountries = async () => {
    try {
      const res = await getAllCountries();

      if (res.data) {
        setCountries(res.data);
      }
    } catch (e) {
      displayError(e);
    }
  };

  const getStates = async () => {
    try {
      const res = await getAllStates();

      if (res.data) {
        setStates(res.data);
      }
    } catch (e) {
      displayError(e);
    }
  };

  const getGenders = async () => {
    try {
      const res = await getLookupsByType({ lookupType: 1 });

      if (res.data) {
        setGenders(res.data);
      }
    } catch (e) {
      displayError(e);
    }
  };

  const getInstructors = async (schoolId) => {
    try {
      const res = await getAllSchoolInstructors({ school_id: schoolId });
      if (res.data) setInstructors(res.data);
    } catch (e) {
      displayError(e);
    }
  };

  const hasColumn = (column) => columns.includes(column);

  // This is the place where we read the CSV file uplodaded by the user and try to
  // match the columns assigning to the existing `availableColumns` array.
  useEffect(() => {
    if (!appendCalled.current && !importResults) {
      csvStudents.map((student) =>
        append({
          index: "",
          school_id: student.school_id,
          instructor_id: student.instructor_id,
          series_id: student.series_id,
          series_name: student.series_name,
          first_name: student.first_name.trim(),
          middle_name: student.middle_name?.trim(),
          last_name: student.last_name?.trim(),
          school_name: student.school_name?.trim(),
          school_code: student.school_code?.trim(),
          instructor_name: student.instructor_name?.trim(),
          title: student.title?.trim(),
          address: student.street_address?.trim(), // "Street Address"
          address_2: student.address_2?.trim(),
          city: student.city?.trim(),
          state: student.state_province?.trim(), // "State/Province"
          zip_code: student.zip_postal_code?.trim(), // "Zip/Postal Code"
          country: student.country?.trim(),
          gender: student.gender?.trim(),
          married: student.married?.trim() === "yes",
          birthdate: student.birthdate?.trim(),
          denomination: student.denomination?.trim(),
          baptized: student.baptized?.trim() === "yes",
          phone: student.phone?.trim(),
          phone_is_sms: student.phone_is_sms?.trim() === "yes",
          phone_2: student.phone_2?.trim(),
          phone_2_is_sms: student.phone_2_is_sms?.trim() === "yes",
          email: student.e_mail_address?.trim(), // "E-mail Address"
          social_media: student.social_media?.trim(),
          referral: student.referrals?.trim(), // "Referrals"
          enrollments: student.enrollments?.trim(),
          registration_number: student.registration_number?.trim(),
        })
      );
      appendCalled.current = true;
    }
  }, []);

  useEffect(() => {
    getSchools();
    getCountries();
    getStates();
    getGenders();

    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const el = document.getElementById("csv-preview-form");
    if (el?.value) el.value = null;
  });

  useEffect(() => {
    if (selectedSchoolId) getInstructors(selectedSchoolId);
  }, [selectedSchoolId]);

  if (loading) return <PageLoader className="md:h-screen" />;

  return showResults ? (
    <CsvResults
      studentImport={studentImport}
      setShowResults={setShowResults}
      onImportExecuted={() => {
        closeModal();
        setRefreshStudents(true);
      }}
      closeModal={closeModal}
    />
  ) : (
    <FormProvider {...methods}>
      <div className={schoolSearchFocused ? "h-[500px]" : "h-[200px]"}>
        <div className="fixed w-full z-1">
          <ActionMenuContainer
            light
            label="CSV students preview"
            clickToClose={closeModal}
          />
          <CsvAutoAssign
            methods={methods}
            schools={schools}
            onFocus={() => setSchoolSearchFocused(true)}
            onBlur={() => setSchoolSearchFocused(false)}
          />
        </div>
      </div>

      <form
        className="bg-gray-100 relative z-[1] mb-24"
        onSubmit={methods.handleSubmit(onSubmit)}
        id="csv-preview-form"
      >
        <Table
          noOverflow
          className="whitespace-nowrap [&_tr>:first-child]:pl-12 [&_tr>:last-child]:pr-12 text-sm"
        >
          <thead className="uppercase [&_th]:sticky [&_th]:top-0 [&_th]:z-[999] [&_th]:bg-white [&_th]:py-4 [&_label]:text-sm">
            <tr>
              <TableHeader />
              <TableHeader classNames="font-bold min-w-[150px]">
                <Checkbox
                  id="title"
                  label="Title"
                  defaultChecked={hasColumn("title")}
                  onClick={(event) => handleRemovedColumns(event, "title")}
                />
              </TableHeader>
              <TableHeader classNames="font-bold min-w-[150px]">
                <Checkbox
                  id="first_name"
                  label="First Name"
                  defaultChecked={true}
                  disabled
                />
              </TableHeader>
              <TableHeader classNames="font-bold  min-w-[150px]">
                <Checkbox
                  id="middle_name"
                  label="Middle Name"
                  defaultChecked={hasColumn("middle_name")}
                  onClick={(event) =>
                    handleRemovedColumns(event, "middle_name")
                  }
                />
              </TableHeader>
              <TableHeader classNames="font-bold min-w-[150px]">
                <Checkbox
                  id="last_name"
                  label="Last Name"
                  defaultChecked={true}
                  disabled
                />
              </TableHeader>
              <TableHeader classNames="font-bold min-w-[180px]">
                <Checkbox
                  id="school"
                  label="School"
                  defaultChecked={true}
                  disabled
                />
              </TableHeader>
              <TableHeader classNames="font-bold min-w-[215px]">
                <Checkbox
                  id="instructor"
                  label="Instructor"
                  defaultChecked={true}
                  disabled
                />
              </TableHeader>
              <TableHeader classNames="font-bold min-w-[200px]">
                <Checkbox
                  id="series_id"
                  label="Course/Seminar"
                  defaultChecked={hasColumn("series_id")}
                  onClick={(event) => handleRemovedColumns(event, "series_id")}
                />
              </TableHeader>
              <TableHeader classNames="font-bold min-w-[100px]">
                <Checkbox
                  id="registration_number"
                  label="Registration Number"
                  defaultChecked={hasColumn("registration_number")}
                  onClick={(event) =>
                    handleRemovedColumns(event, "registration_number")
                  }
                />
              </TableHeader>
              <TableHeader classNames="font-bold min-w-[160px]">
                <Checkbox
                  id="referral"
                  label="Referrals"
                  defaultChecked={hasColumn("referral")}
                  onClick={(event) => handleRemovedColumns(event, "referral")}
                />
              </TableHeader>
              <TableHeader classNames="font-bold min-w-[150px]">
                <Checkbox
                  id="address"
                  label="Address"
                  defaultChecked={hasColumn("address")}
                  onClick={(event) => handleRemovedColumns(event, "address")}
                />
              </TableHeader>
              <TableHeader classNames="font-bold min-w-[100px]">
                <Checkbox
                  id="address_2"
                  label="Address 2"
                  defaultChecked={hasColumn("address_2")}
                  onClick={(event) => handleRemovedColumns(event, "address_2")}
                />
              </TableHeader>
              <TableHeader classNames="font-bold min-w-[100px]">
                <Checkbox
                  id="city"
                  label="City"
                  defaultChecked={hasColumn("city")}
                  onClick={(event) => handleRemovedColumns(event, "city")}
                />
              </TableHeader>
              <TableHeader classNames="font-bold min-w-[190px]">
                <Checkbox
                  id="state"
                  label="State/Province"
                  defaultChecked={hasColumn("state")}
                  onClick={(event) => handleRemovedColumns(event, "state")}
                />
              </TableHeader>
              <TableHeader classNames="font-bold min-w-[110px]">
                <Checkbox
                  id="zip_code"
                  label="Zip/Postal"
                  defaultChecked={hasColumn("zip_code")}
                  onClick={(event) => handleRemovedColumns(event, "zip_code")}
                />
              </TableHeader>
              <TableHeader classNames="font-bold min-w-[190px]">
                <Checkbox
                  id="country"
                  label="Country"
                  defaultChecked={hasColumn("country")}
                  onClick={(event) => handleRemovedColumns(event, "country")}
                />
              </TableHeader>
              <TableHeader classNames="font-bold min-w-[180px]">
                <Checkbox
                  id="gender"
                  label="Gender"
                  defaultChecked={hasColumn("gender")}
                  onClick={(event) => handleRemovedColumns(event, "gender")}
                />
              </TableHeader>
              <TableHeader classNames="font-bold min-w-[100px]">
                <Checkbox
                  id="married"
                  label="Married"
                  defaultChecked={hasColumn("married")}
                  onClick={(event) => handleRemovedColumns(event, "married")}
                />
              </TableHeader>
              <TableHeader classNames="font-bold min-w-[130px]">
                <Checkbox
                  id="birthdate"
                  label="Birthdate"
                  defaultChecked={hasColumn("birthdate")}
                  onClick={(event) => handleRemovedColumns(event, "birthdate")}
                />
              </TableHeader>
              <TableHeader classNames="font-bold min-w-[130px]">
                <Checkbox
                  id="denomination"
                  label="Denomination"
                  defaultChecked={hasColumn("denomination")}
                  onClick={(event) =>
                    handleRemovedColumns(event, "denomination")
                  }
                />
              </TableHeader>
              <TableHeader classNames="font-bold min-w-[100px]">
                <Checkbox
                  id="baptized"
                  label="Baptized"
                  defaultChecked={hasColumn("baptized")}
                  onClick={(event) => handleRemovedColumns(event, "baptized")}
                />
              </TableHeader>
              <TableHeader classNames="font-bold min-w-[140px]">
                <Checkbox
                  id="phone"
                  label="Phone"
                  defaultChecked={hasColumn("phone")}
                  onClick={(event) => handleRemovedColumns(event, "phone")}
                />
              </TableHeader>
              <TableHeader classNames="font-bold min-w-[100px]">
                <Checkbox
                  id="phone_is_sms"
                  label="SMS"
                  defaultChecked={hasColumn("phone_is_sms")}
                  onClick={(event) =>
                    handleRemovedColumns(event, "phone_is_sms")
                  }
                />
              </TableHeader>
              <TableHeader classNames="font-bold min-w-[140px]">
                <Checkbox
                  id="phone_2"
                  label="Phone 2"
                  defaultChecked={hasColumn("phone_2")}
                  onClick={(event) => handleRemovedColumns(event, "phone_2")}
                />
              </TableHeader>
              <TableHeader classNames="font-bold min-w-[120px]">
                <Checkbox
                  id="phone_2_is_sms"
                  label="Phone 2 SMS"
                  defaultChecked={hasColumn("phone_2_is_sms")}
                  onClick={(event) =>
                    handleRemovedColumns(event, "phone_2_is_sms")
                  }
                />
              </TableHeader>
              <TableHeader classNames="font-bold min-w-[190px]">
                <Checkbox
                  id="email"
                  label="Email"
                  defaultChecked={hasColumn("email")}
                  onClick={(event) => handleRemovedColumns(event, "email")}
                />
              </TableHeader>
              <TableHeader classNames="font-bold min-w-[160px]">
                <Checkbox
                  id="social_media"
                  label="Social Media"
                  defaultChecked={hasColumn("social_media")}
                  onClick={(event) =>
                    handleRemovedColumns(event, "social_media")
                  }
                />
              </TableHeader>
            </tr>
          </thead>
          <tbody className="bg-gray-100">
            {fields.map((student, index) =>
              selectedRow !== index ? (
                <CsvReadOnlyRow
                  key={index}
                  student={student}
                  index={index}
                  onClick={(cell) => {
                    setSelectedRow(index);
                    setFocusedCell(cell);
                  }}
                  handleRemovedRows={handleRemovedRows}
                  removedRows={removedRows}
                  methods={methods}
                  importSchoolInstructors={instructors}
                  selectedSchool={schools?.find(
                    (s) => s.id === selectedSchoolId
                  )}
                />
              ) : (
                <CsvStudentsRow
                  key={index}
                  index={index}
                  student={student}
                  schools={schools}
                  methods={methods}
                  handleRemovedRows={handleRemovedRows}
                  removedRows={removedRows}
                  countries={countries}
                  states={states}
                  genders={genders}
                  hasColumn={hasColumn}
                  importSchoolId={selectedSchoolId}
                  importSeriesId={selectedSeriesId}
                  focusedCell={focusedCell}
                  importSchoolInstructors={instructors}
                />
              )
            )}
          </tbody>
        </Table>

        <div className="flex justify-center pt-4 fixed bottom-8 w-full">
          <div className="text-center md:mr-4">
            <Button type="submit" variant="primary" classNames="shadow-lg">
              Save and preview
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
