import React, { useEffect, useState } from "react";
import { getAllCourseSchoolsRequest } from "../../../../requests/courses";
import { useParams } from "react-router-dom";
import Card from "../../../UI/Card";
import { displayError } from "../../../../utils/displayError";
import SchoolsTable from "../../../Schools/SchoolsTable";
import PageLoader from "../../../UI/PageLoader";

export default function CourseSchools() {
  const params = useParams();
  const [schools, setSchools] = useState();
  const [loading, setLoading] = useState(false);

  const getAllCourseSchools = async () => {
    try {
      setLoading(true);
      const res = await getAllCourseSchoolsRequest({
        courseId: params.courseId,
      });

      if (res.data) {
        setSchools(res.data);
      }
    } catch (error) {
      displayError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllCourseSchools();
  }, []);

  return (
    <Card>
      {schools ? (
        <SchoolsTable schools={schools} loading={loading} />
      ) : (
        <PageLoader variant="primary" />
      )}
    </Card>
  );
}
