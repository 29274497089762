import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useMediaQuery } from "../../../../utils/useMediaQuery";
import { displayError } from "../../../../utils/displayError";
import { getAllSchoolStudents } from "../../../../requests/schools";
import {
  Link,
  useOutletContext,
  useRouteLoaderData,
  useSearchParams,
} from "react-router-dom";
import Card from "../../../UI/Card";
import { checkPermission } from "../../../../utils/checkPermission";
import QuickFind from "../../../UI/QuickFind";
import TableHeader from "../../../UI/Table/TableHeader";
import Table from "../../../UI/Table/Table";
import EmptyTable from "../../../UI/EmptyTable";
import { fullName } from "../../../../utils/fullName";
import { highlightText } from "../../../../utils/highlightText";
import { hoverLink } from "../../../../utils/hoverLink";
import PageLoader from "../../../UI/PageLoader";
import TableCell from "../../../UI/Table/TableCell";
import StudentEnrollmentsCell from "../../../UI/StudentEnrollmentsCell";
import { addressMultiLine } from "../../../../utils/fullAddress";
import SchoolStudentsMobile from "./Mobile/SchoolStudentsMobile";
import EditOnHover from "../../../UI/EditOnHover";

export default function SchoolStudents() {
  const isMobile = useMediaQuery("md", "down");
  const { setActionMenuModal } = useOutletContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.get("query") || "";
  const sortBy = searchParams.get("sortBy") || "created_at";
  const orderBy = searchParams.get("orderBy") || "asc";
  const school = useRouteLoaderData("school").data;
  const [students, setStudents] = useState();
  const [loading, setLoading] = useState(false);

  const getParams = () => {
    const queryParams = {
      q: { sorts: `${sortBy} ${orderBy}` },
    };

    // Only add the search parameter if query is not empty.
    // Also using Ransacker gem Search Matcher `*_i_cont_all` pattern.
    // Splitting the query terms into an array for the `cont` matcher.
    if (query.trim()) {
      const attributes = [
        "full_name",
        "country",
        "state",
        "city",
        "zip_code",
        "address",
        "address_2",
        "enrollments_registration_number",
      ];
      queryParams.q[`${attributes.join("_or_")}_i_cont_all`] = query
        .trim()
        .split(" ");
    }

    return queryParams;
  };

  const getSchoolInstructors = async () => {
    setLoading(true);

    try {
      const res = await getAllSchoolStudents({
        school_id: school.id,
        ...getParams(),
      });

      if (res.data) {
        setStudents(res.data);
      }
    } catch (e) {
      displayError(e);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (data) => {
    setSearchParams((prev) => {
      prev.set("query", data.search || "");
      return prev;
    });
  };

  // Fetch students when the component mounts
  // and when pagination or section changes.
  useEffect(() => {
    getSchoolInstructors();
  }, [searchParams]);

  return isMobile ? (
    <SchoolStudentsMobile students={students} />
  ) : (
    <>
      <Card>
        <div className="flex items-center justify-between mb-6">
          <div className="relative">
            {checkPermission(["school_admin", "conference_admin"]) && (
              <img
                src="/add-new.svg"
                className="absolute left-0 top-1/2 mt-0 h-auto max-w-full -translate-x-10 -translate-y-1/2 transform cursor-pointer"
                width={30}
                height={30}
                alt="Add New"
                onClick={() =>
                  setActionMenuModal({
                    open: true,
                    defaultRender: "NewStudent",
                  })
                }
              />
            )}

            <h1 className="text-xl font-bold text-primary">STUDENTS</h1>
          </div>
          <div className="flex">
            <QuickFind onSubmit={onSubmit} value={query} />
          </div>
        </div>
        {students?.length > 0 ? (
          <Table>
            <thead>
              <tr>
                <TableHeader
                  isSortable
                  isMultiSort
                  multiSortOptions={[
                    { name: "First", value: "first_name" },
                    { name: "Last", value: "last_name" },
                  ]}
                >
                  STUDENT
                </TableHeader>
                <TableHeader
                  isSortable
                  isMultiSort
                  sortBy="enrollments"
                  multiSortOptions={[
                    {
                      name: "Latest Lesson Sent",
                      value: "cached_latest_sent_date",
                    },
                    {
                      name: "Registration number",
                      value: "cached_registration_numbers",
                    },
                  ]}
                >
                  ENROLLMENTS
                </TableHeader>
                <TableHeader
                  isSortable
                  isMultiSort
                  multiSortOptions={[
                    { name: "City", value: "city" },
                    { name: "State", value: "state" },
                    { name: "Zip", value: "zip_code" },
                    { name: "Country", value: "country" },
                  ]}
                >
                  ADDRESS
                </TableHeader>
                <TableHeader isSortable sortBy="created_at">
                  CREATED
                </TableHeader>
                <TableHeader isSortable sortBy="instructor_first_name">
                  INSTRUCTOR
                </TableHeader>
              </tr>
            </thead>
            <tbody>
              {students.map((student) => (
                <tr key={student.id} className={loading ? "opacity-50" : ""}>
                  <TableCell>
                    <EditOnHover to={`/students/${student.id}/profile`}>
                      <Link
                        className={`${hoverLink}`}
                        to={`/students/${student.id}/overview`}
                      >
                        {highlightText(
                          fullName(
                            student.first_name,
                            student.middle_name,
                            student.last_name
                          ),
                          query
                        )}
                      </Link>
                    </EditOnHover>
                  </TableCell>
                  <TableCell classNames="text-sm min-w-[350px]">
                    <StudentEnrollmentsCell
                      student={student}
                      searchQuery={query}
                    />
                  </TableCell>
                  <TableCell classNames="whitespace-nowrap">
                    {addressMultiLine(
                      {
                        address: student.address,
                        address_2: student.address_2,
                        city: student.city,
                        state: student.state,
                        country:
                          student.country !== "United States"
                            ? student.country
                            : undefined,
                        zip_code: student.zip_code,
                      },
                      { highlight: searchParams.get("sortBy"), query }
                    )}
                  </TableCell>
                  <TableCell>
                    {dayjs(student.created_at).format("MM/DD/YYYY")}
                  </TableCell>
                  <TableCell>
                    <Link
                      className={hoverLink}
                      to={`/instructors/${student.instructor_id}/overview`}
                    >
                      {student.instructor_name}
                    </Link>
                    {student.student_shares.length > 0 && (
                      <ul className="ml-2 text-xs text-yellow-500">
                        {student.student_shares.map((share) => (
                          <li key={share.id}>{share.user_name}</li>
                        ))}
                      </ul>
                    )}
                  </TableCell>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : students ? (
          <EmptyTable message="No students found." />
        ) : (
          <PageLoader />
        )}
      </Card>
    </>
  );
}
