import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ActionMenuContainer from "../../ActionMenu/ActionMenuContainer";
import { useForm, FormProvider } from "react-hook-form";
import Button from "../../UI/Button";
import Modal from "../../UI/Modal";
import Pill from "../../UI/Pill";
import { displayError } from "../../../utils/displayError";
import { getAllSchools } from "../../../requests/schools";
import { addressMultiLine } from "../../../utils/fullAddress";

export default function ClaimedZipCodes({ open, setOpen }) {
  const methods = useForm();
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState();
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
    setSearchResults(null);
    methods.reset();
  };

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      const response = await getAllSchools({
        params: {
          pagination: false,
          claimed_zip_match: data.zip_code,
        },
      });
      setSearchResults(response.data.schools);
    } catch (e) {
      displayError(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal open={open} setOpen={handleClose}>
      <div className="bg-neutral-600 relative">
        <ActionMenuContainer
          label="Search Claimed Zip Codes"
          clickToClose={handleClose}
        >
          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit(onSubmit)}
              className="flex flex-col items-center gap-4 max-w-[600px] m-auto mt-10"
            >
              <div className="relative flex-grow w-full flex items-center">
                <input
                  {...methods.register("zip_code", {
                    required: "Zip code is required",
                  })}
                  type="text"
                  placeholder="Zip Code starts with"
                  className="w-full h-[38px] px-4 rounded-md border border-neutral-400 bg-transparent"
                />
                <Button
                  disabled={!methods.formState.isValid || loading}
                  type="submit"
                  classNames="h-[38px] whitespace-nowrap ml-2"
                >
                  Find
                </Button>
              </div>
              {methods.formState.errors.zip_code && (
                <span className="text-red-500 text-sm">
                  {methods.formState.errors.zip_code.message}
                </span>
              )}
            </form>
          </FormProvider>

          {loading ? (
            <div className="flex justify-center items-center h-[300px]">
              <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-white"></div>
            </div>
          ) : searchResults?.length > 0 ? (
            <div className="mt-6 overflow-x-auto">
              <table className="w-full text-left">
                <thead>
                  <tr className="border-b border-neutral-400">
                    <th className="py-2 px-4">SCHOOL</th>
                    <th className="py-2 px-4">CHURCH ADDRESS</th>
                    <th className="py-2 px-4">SCHOOL ADDRESS</th>
                    <th className="py-2 px-4">COURSES</th>
                    <th className="py-2 px-4">OPTIONS</th>
                  </tr>
                </thead>
                <tbody>
                  {searchResults.map((school) => (
                    <tr
                      key={school.id}
                      className="border-b border-neutral-400 hover:bg-neutral-500 cursor-pointer transition-colors duration-200"
                      onClick={() => {
                        navigate(`/schools/${school.id}/overview`);
                      }}
                    >
                      <td className="py-2 px-4">
                        (#{school.school_code}) {school.church_name}
                      </td>
                      <td className="py-2 px-4 whitespace-nowrap">
                        {addressMultiLine({
                          address: school.church_address,
                          address_2: school.church_address_2,
                          city: school.church_city,
                          state: school.church_state,
                          zip_code: school.church_zip_code,
                          country: school.church_country,
                        })}
                      </td>
                      <td className="py-2 px-4 whitespace-nowrap">
                        {addressMultiLine({
                          address: school.school_address,
                          address_2: school.school_address_2,
                          city: school.school_city,
                          state: school.school_state,
                          zip_code: school.school_zip_code,
                          country: school.school_country,
                        })}
                      </td>
                      <td className="py-2 px-4">
                        {school.courses.map((course) => (
                          <div key={course.id}>{course.name}</div>
                        ))}
                      </td>
                      <td className="py-2 px-4">
                        {[
                          {
                            name: "Prison Ministry",
                            value: school.prison_ministry,
                          },
                          { name: "Signs Box", value: school.signs_box },
                          { name: "No PO Box", value: school.no_po_box },
                        ]
                          .filter((option) => option.value)
                          .map((option, index) => (
                            <React.Fragment key={index}>
                              <Pill color="success" classNames="mr-2 mb-2">
                                {option.name}
                              </Pill>
                              <br />
                            </React.Fragment>
                          ))}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : searchResults ? (
            <div className="h-[300px] flex justify-center items-center">
              <p className="text-red-400 font-bold">
                No schools found for this zip code
              </p>
            </div>
          ) : (
            <div className="h-[300px]" />
          )}
        </ActionMenuContainer>
      </div>
    </Modal>
  );
}
