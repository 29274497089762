import React, { useEffect, useState } from "react";
import Table from "../../../../../UI/Table/Table";
import TableHeader from "../../../../../UI/Table/TableHeader";
import { useMediaQuery } from "../../../../../../utils/useMediaQuery";
import { useOutletContext, useRouteLoaderData } from "react-router-dom";
import EmptyTable from "../../../../../UI/EmptyTable";
import PageLoader from "../../../../../UI/PageLoader";
import { getAllCourseDecisionsRequest } from "../../../../../../requests/courses";
import { getAllSeminarPresentationsRequest } from "../../../../../../requests/seminars";
import { displayError } from "../../../../../../utils/displayError";
import PresentationRow from "./PresentationRow";
import PresentationsMobile from "./Mobile/PresentationsMobile";

export default function Presentations() {
  const isMobile = useMediaQuery("md", "down");
  const { user, seriesEnrollment } = useOutletContext();
  const enrollment = useRouteLoaderData(seriesEnrollment).data;
  const [decisionOptions, setDecisionOptions] = useState([]);
  const [presentations, setPresentations] = useState();

  const getAllCourseDecisions = async () => {
    const res = await getAllCourseDecisionsRequest({
      courseId: enrollment.series_id,
    });

    if (res.data) {
      setDecisionOptions(res.data);
    }
  };

  const getAllSeminarPresentations = async () => {
    try {
      const res = await getAllSeminarPresentationsRequest({
        seminarId: enrollment.series_id,
      });

      if (res.data) {
        setPresentations(res.data);
      }
    } catch (e) {
      displayError(e);
    }
  };

  useEffect(() => {
    getAllCourseDecisions();
    getAllSeminarPresentations();
  }, []);

  return isMobile ? (
    <PresentationsMobile
      presentations={presentations}
      enrollment={enrollment}
      user={user}
      decisionOptions={decisionOptions}
    />
  ) : presentations ? (
    presentations?.length ? (
      <Table>
        <thead>
          <tr>
            <TableHeader>DATE</TableHeader>
            <TableHeader>PRESENTATION</TableHeader>
            <TableHeader classNames="text-center">ATTENDED</TableHeader>
            <TableHeader>NOTES & PRAYER REQUESTS</TableHeader>
            <TableHeader>DECISIONS</TableHeader>
            <TableHeader classNames="text-center">ACTIONS</TableHeader>
          </tr>
        </thead>
        <tbody>
          {presentations.map((presentation) => (
            <PresentationRow
              key={presentation.id}
              enrollment={enrollment}
              presentation={presentation}
              decisionOptions={decisionOptions}
              user={user}
            />
          ))}
        </tbody>
      </Table>
    ) : (
      <EmptyTable message="No Presentations recorded." />
    )
  ) : (
    <PageLoader />
  );
}
