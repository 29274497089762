import React, { useEffect, useState } from "react";
import { useMediaQuery } from "../../utils/useMediaQuery";
import Card from "../UI/Card";
import Button from "../UI/Button";
import Select from "../UI/Inputs/Select/Select";
import SelectItem from "../UI/Inputs/Select/SelectItem";
import { FormProvider, useForm } from "react-hook-form";
import InputGroup from "../UI/Inputs/InputGroup";
import { getAllSeminars } from "../../requests/seminars";
import { displayError } from "../../utils/displayError";
import EmptyTable from "../UI/EmptyTable";
import { useSearchParams } from "react-router-dom";
import { reportsChildMapping } from "../../utils/reports/reportsChildMapping";
import {
  studentReportOptions,
  schoolReportOptions,
} from "../../utils/reports/reportOptions";
import { getAllConferences } from "../../requests/conferences";
import { checkPermission } from "../../utils/checkPermission";

export default function Reports() {
  const isMobile = useMediaQuery("md", "down");
  const [searchParams, setSearchParams] = useSearchParams();
  const methods = useForm();
  const [seminars, setSeminars] = useState([]);
  const [conferences, setConferences] = useState([]);
  const isGlobalAdmin = checkPermission(["global_admin"]);
  const isAdmin = checkPermission([
    "global_admin",
    "school_admin",
    "conference_admin",
  ]);
  const viewOnly = Boolean(searchParams.get("viewOnly"));

  const returnSelectedReportOption = (report_type) => {
    const options = studentReportOptions.concat(schoolReportOptions);
    return options.find((option) => option.value === report_type);
  };

  const selectedReportOption = returnSelectedReportOption(
    methods.watch("report_type")
  );

  const reportToRender = returnSelectedReportOption(
    searchParams.get("report_type")
  )?.component;

  const ReportResult = reportsChildMapping[reportToRender];

  const getSeminars = async () => {
    try {
      const res = await getAllSeminars({
        params: { pagination: false },
      });

      if (res.data) {
        setSeminars(res.data.seminars);
      }
    } catch (error) {
      displayError(error);
    }
  };

  const getConferences = async () => {
    try {
      const res = await getAllConferences();

      if (res.data) {
        setConferences(res.data);
      }
    } catch (error) {
      displayError(error);
    }
  };

  const onSubmit = (data) => {
    setSearchParams({
      report_type: data.report_type,
      ...(selectedReportOption.requireSeminar
        ? { seminar_id: data.seminar_id }
        : {}),
      ...(selectedReportOption.school && data.active
        ? { status: data.active }
        : {}),
      ...(selectedReportOption.school && data.conference_code
        ? { conference_code: data.conference_code }
        : {}),
      ...(selectedReportOption.school && data.discovery_center
        ? { discovery_center: data.discovery_center }
        : {}),
    });
  };

  useEffect(() => {
    if (isGlobalAdmin) {
      getConferences();
    }

    getSeminars();
  }, []);

  useEffect(() => {
    if (viewOnly) {
      methods.setValue("report_type", searchParams.get("report_type"));
    }
  }, [searchParams]);

  return isMobile ? (
    <Card classNames="lg:mt-10">
      <h1 className="text-xl font-bold text-primary">REPORTS</h1>
      <p className="text-gray-500 mt-4">
        Reports are not available on mobile. Please use a bigger screen device
        to view reports.
      </p>
    </Card>
  ) : (
    <Card classNames="lg:mt-10">
      {!viewOnly && (
        <div className="flex">
          <h1 className="text-xl font-bold text-primary">REPORTS</h1>
          <div className="absolute left-1/2 transform -translate-x-1/2">
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <InputGroup classNames="mb-0">
                  <Select
                    id="report_type"
                    defaultValue={searchParams.get("report_type")}
                  >
                    <SelectItem value="">Select a Report</SelectItem>
                    <optgroup label="Students">
                      {studentReportOptions
                        .filter((option) => !option.requireAdmin || isAdmin)
                        .map((option) => (
                          <SelectItem key={option.value} value={option.value}>
                            {option.label}
                          </SelectItem>
                        ))}
                    </optgroup>
                    {isGlobalAdmin && (
                      <optgroup label="Schools">
                        {schoolReportOptions.map((option) => (
                          <SelectItem key={option.value} value={option.value}>
                            {option.label}
                          </SelectItem>
                        ))}
                      </optgroup>
                    )}
                  </Select>
                  {selectedReportOption?.requireSeminar &&
                    seminars.length > 0 && (
                      <Select
                        id="seminar_id"
                        defaultValue={searchParams.get("seminar_id")}
                        isRequired
                      >
                        <SelectItem value="">Select a Seminar</SelectItem>
                        {seminars.map((seminar) => (
                          <SelectItem key={seminar.id} value={seminar.id}>
                            {seminar.name}
                          </SelectItem>
                        ))}
                      </Select>
                    )}
                  {selectedReportOption?.school &&
                    !selectedReportOption?.noFilters && (
                      <>
                        <Select
                          id="active"
                          defaultValue={searchParams.get("status")}
                        >
                          <SelectItem value="">Status</SelectItem>
                          <SelectItem value="active">Active</SelectItem>
                          <SelectItem value="inactive">Inactive</SelectItem>
                        </Select>
                        <Select
                          id="conference_code"
                          defaultValue={searchParams.get("conference_code")}
                        >
                          <SelectItem value="">All conferences</SelectItem>
                          {conferences.map((conference) => (
                            <SelectItem
                              key={conference.id}
                              value={conference.conference_code}
                            >
                              {conference.name}
                            </SelectItem>
                          ))}
                        </Select>
                        <Select
                          id="discovery_center"
                          defaultValue={searchParams.get("discovery_center")}
                        >
                          <SelectItem value="">
                            Select a Discovery Center
                          </SelectItem>
                          <SelectItem value="true">
                            Is a Discovery Center
                          </SelectItem>
                          <SelectItem value="false">
                            Is not a Discovery Center
                          </SelectItem>
                        </Select>
                      </>
                    )}
                  {selectedReportOption && (
                    <Button type="submit" variant="primary">
                      Run Report
                    </Button>
                  )}
                </InputGroup>
              </form>
            </FormProvider>
          </div>
        </div>
      )}

      <>
        {selectedReportOption && searchParams.get("report_type") ? (
          <ReportResult
            viewOnly={viewOnly}
            seminar={seminars.find(
              (seminar) => seminar.id === searchParams.get("seminar_id")
            )}
          />
        ) : (
          <EmptyTable message="Please select a report" />
        )}
      </>
    </Card>
  );
}
