import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import Card from "../../../UI/Card";
import ContactInformationForm from "./ContactInformationForm";
import AccountForm from "./AccountForm";
import { useNavigate, useRouteLoaderData } from "react-router-dom";
import Button from "../../../UI/Button";
import Modal from "../../../UI/Modal";
import ConfirmationDialog from "../../../UI/ConfirmationDialog";
import {
  deleteUserRequest,
  updateUserRequest,
  welcomeEmailUserRequest,
} from "../../../../requests/users";
import RecentChanges from "../../../Versions/RecentChanges";
import { ToastNotification } from "../../../UI/ToastNotification";
import { displayError } from "../../../../utils/displayError";
import { checkPermission } from "../../../../utils/checkPermission";

export default function InstructorProfile() {
  const navigate = useNavigate();
  const instructor = useRouteLoaderData("user").data;
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const isAdmin = checkPermission([
    "global_admin",
    "conference_admin",
    "school_admin",
  ]);
  const [justSentWelcomeEmail, setJustSentWelcomeEmail] = useState(false);

  const defaultRolesValues = instructor.roles.map((role) => {
    return {
      role_id: role.id,
      organization_id: role.organization_id,
      role_type: role.role_type,
      organization_name: role.organization_name,
      allow_sharing: role.allow_sharing,
    };
  });

  const methods = useForm({
    defaultValues: {
      roles_attributes: defaultRolesValues,
    },
  });

  const onSubmit = async (data) => {
    const params = {
      user: {
        ...data,
        roles_attributes: data.roles_attributes.map((role) => ({
          id: role.role_id,
          organization_id: role.organization_id,
          organization_type:
            role.role_type === "conference_admin" ? "Conference" : "School",
          role_type: role.role_type,
          allow_sharing: role.allow_sharing,
          _destroy: role.delete,
        })),
        userId: instructor.id,
      },
    };

    try {
      const res = await updateUserRequest({
        ...params,
        userId: instructor.id,
      });

      if (res) {
        ToastNotification("success", "Instructor updated successfully.");
        navigate(".", { replace: true });
      }
    } catch (e) {
      displayError(e);
    }
  };

  const deleteInstructor = async () => {
    try {
      const res = await deleteUserRequest({ userId: instructor.id });

      if (res) {
        ToastNotification("success", "Instructor deleted successfully.");
        navigate(`/instructors`);
      }
    } catch (e) {
      ToastNotification("error", "Error deleting instructor.");
    }
  };

  const handleDeleteModal = (e) => {
    e.preventDefault();
    setDeleteModalOpen(true);
  };

  const handleResendWelcomeEmail = async (e) => {
    e.preventDefault();
    setJustSentWelcomeEmail(true);

    try {
      await welcomeEmailUserRequest({ userId: instructor.id });
      ToastNotification("success", "Welcome email sent successfully.");
    } catch (e) {
      displayError(e);
    }
  };

  return (
    <div className="mb-10 md:mb-0">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Card classNames="p-3 rounded-2xl md:rounded md:p-6">
            <div className="md:flex">
              <div className="md:w-3/5 md:border-r border-black md:pr-6">
                <ContactInformationForm instructor={instructor} />
              </div>
              <div className="md:w-2/5 md:pl-6">
                <AccountForm instructor={instructor} />
              </div>
            </div>

            <RecentChanges itemType="instructor" itemId={instructor.id} />
          </Card>
          <div className="md:flex items-center p-6">
            <Button
              classNames="md:w-auto w-full mr-4 mb-4 md:mb-0"
              variant="primary"
              type="submit"
            >
              Update Instructor
            </Button>
            <Button
              type="button"
              classNames="md:w-auto w-full mr-4"
              variant="secondary"
              onClick={(e) => handleDeleteModal(e)}
              disabled={instructor.students_count > 0}
            >
              Delete Instructor
            </Button>

            {isAdmin && (
              <Button
                type="button"
                classNames="md:w-auto w-full mr-4"
                variant="secondary"
                onClick={(e) => handleResendWelcomeEmail(e)}
                disabled={justSentWelcomeEmail}
              >
                {justSentWelcomeEmail
                  ? "Welcome Email Sent"
                  : "Resend Welcome Email"}
              </Button>
            )}

            <div>
              {instructor.students_count > 0 && (
                <p>
                  This instructor has {instructor.students_count} students. All
                  students must be removed before deleting an instructor.
                </p>
              )}
            </div>
          </div>
        </form>
      </FormProvider>

      <Modal dark open={deleteModalOpen} setOpen={setDeleteModalOpen}>
        <ConfirmationDialog
          closeModal={() => setDeleteModalOpen(false)}
          confirmAction={() => deleteInstructor()}
        />
      </Modal>
    </div>
  );
}
