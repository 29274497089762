import React, { useEffect, useRef, useState } from "react";
import Button from "../../../UI/Button";
import TableHeader from "../../../UI/Table/TableHeader";
import Table from "../../../UI/Table/Table";
import { useNavigate } from "react-router-dom";
import TableCell from "../../../UI/Table/TableCell";
import { createStudentRequest } from "../../../../requests/students";
import { ToastNotification } from "../../../UI/ToastNotification";
import { addressMultiLine } from "../../../../utils/fullAddress";
import { createEnrollmentRequest } from "../../../../requests/enrollments";
import { displayError } from "../../../../utils/displayError";

export default function Success({
  setSuccess,
  setSuggestedSchools,
  setCreateNewStudent,
  selectedCourseId,
  school,
  studentData,
}) {
  const navigate = useNavigate();
  const [studentId, setStudentId] = useState(null);
  const appendCalled = useRef(false);

  const handleClick = async () => {
    setSuccess(false);
    setCreateNewStudent(true);
  };

  const createStudent = async () => {
    try {
      const res = await createStudentRequest({
        ...studentData,
        school_id: school.id,
        instructor_id: school.head_school_admin_id,
        series_id: selectedCourseId,
      });

      if (res.data) {
        setStudentId(res.data.id);

        let enrollmentRequestData = {
          student_id: res.data.id,
          series_id: selectedCourseId,
          school_id: school.id,
        };

        await createEnrollmentRequest(enrollmentRequestData);

        ToastNotification("success", "Student created successfully.");
      }
    } catch (e) {
      setSuccess(false);
      setSuggestedSchools(true);
      displayError(e);
    }
  };

  useEffect(() => {
    if (!appendCalled.current) {
      createStudent();
      appendCalled.current = true;
    }
  }, []);

  return (
    <>
      <p className="text-[15px] text-center pb-6">
        Student has been automatically assigned to:
      </p>
      <Table>
        <thead>
          <tr>
            <TableHeader classNames="text-base font-extralight">
              School Name
            </TableHeader>
            <TableHeader classNames="text-base font-extralight">
              Church Address
            </TableHeader>
            <TableHeader classNames="text-base font-extralight">
              School Address
            </TableHeader>
          </tr>
        </thead>
        <tbody>
          <tr>
            <TableCell>
              (#{school.school_code}) {school.church_name}
            </TableCell>
            <TableCell>
              {addressMultiLine({
                address: school.school_address,
                address2: school.school_address_2,
                city: school.school_city,
                state: school.school_state,
                country: school.school_country,
                zip_code: school.school_zip_code,
              })}
            </TableCell>
            <TableCell>
              {addressMultiLine({
                address: school.church_address,
                address2: school.church_address_2,
                city: school.church_city,
                state: school.church_state,
                country: school.church_country,
                zip_code: school.church_zip_code,
              })}
            </TableCell>
          </tr>
        </tbody>
      </Table>
      <div className="text-center mt-4">
        <Button
          variant="primary"
          classNames="mr-4"
          onClick={() => handleClick()}
        >
          Add New Student
        </Button>
        <Button
          variant="primary"
          onClick={() => navigate(`/students/${studentId}/overview`)}
        >
          View Student Overview
        </Button>
      </div>
    </>
  );
}
