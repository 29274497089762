import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import Button from "../../../UI/Button";
import { getAllSchools } from "../../../../requests/schools";
import InputGroup from "../../../UI/Inputs/InputGroup";
import SearchSchool from "../../../UI/SearchSchool";
import { checkPermission } from "../../../../utils/checkPermission";

export default function PlaceManually({ searchFullNameArray }) {
  const navigate = useNavigate();
  const methods = useForm();
  const [schools, setSchools] = useState([]);
  const isAdmin = checkPermission(["school_admin", "conference_admin"]);

  const selectedSchool = schools.find(
    (school) => school.id === methods.watch("school")?.value
  );

  const getSchools = async () => {
    const res = await getAllSchools({ params: { pagination: false } });
    if (res.data) {
      setSchools(res.data.schools);
    }
  };

  const onSubmit = (data) =>
    navigate(
      `/students/new?school_id=${data.school?.value}&student_search_name=${searchFullNameArray}`
    );

  useEffect(() => {
    getSchools();
  }, []);

  return (
    <>
      <h2 className="text-2xl">Place in School Manually</h2>
      <p className="text-[15px]">
        Select the school you would like to place the new student in.
      </p>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="mt-6 rounded-md min-h-[350px]"
        >
          {schools.length > 0 && (
            <InputGroup>
              <SearchSchool
                id="school"
                options={schools.map((school) => ({
                  value: school.id,
                  label: school.display_name,
                }))}
                isRequired
              />
            </InputGroup>
          )}

          <p className="text-[15px] mb-6 mt-4">
            To continue creating the new student, click the button below to
            complete the new student profile and enrollments.
          </p>

          {selectedSchool && !selectedSchool?.head_school_admin && (
            <p className="text-red-400 mb-6">
              The selected school needs a <b>Head School Admin</b> before it can
              accept students.{" "}
              {isAdmin && (
                <Link
                  className="text-blue-300"
                  to={`/schools/${selectedSchool.id}/overview`}
                >
                  Manage School
                </Link>
              )}
            </p>
          )}

          <div className="text-center">
            <Button
              type="submit"
              variant="primary"
              disabled={!selectedSchool?.head_school_admin}
            >
              Create New Student
            </Button>
          </div>
        </form>
      </FormProvider>
    </>
  );
}
