import React, { useState, useEffect } from "react";
import CSVReader from "react-csv-reader";
import { ArrowUpOnSquareIcon } from "@heroicons/react/16/solid";
import {
  createStudentImportRequest,
  getAllStudentImportsRequest,
  fetchStudentImportRequest,
  deleteStudentImportRequest,
} from "../../../requests/studentImports";
import { ToastNotification } from "../../UI/ToastNotification";
import PageLoader from "../../UI/PageLoader";
import CsvModal from "./CsvModal";
import CsvStudents from "./CsvStudents";

export default function CsvImport({ setShowCsvUpload, setRefreshStudents }) {
  const [csvPreviewModal, setCsvPreviewModal] = useState({ open: false });
  const [studentImports, setStudentImports] = useState();
  const [studentImport, setStudentImport] = useState();
  const [loading, setLoading] = useState(false);

  const getStudentImports = async () => {
    setLoading(true);
    const res = await getAllStudentImportsRequest();
    setStudentImports(res.data.student_imports);
    setLoading(false);
  };

  const fetchStudentImport = async (id) => {
    setLoading(true);
    const res = await fetchStudentImportRequest(id);
    setStudentImport(res.data.student_import);
    setCsvPreviewModal({ open: true });
    setLoading(false);
  };

  const deleteStudentImport = async (id) => {
    if (!window.confirm("Are you sure?")) return;

    const res = await deleteStudentImportRequest(id);
    if (res.status === 204) {
      setStudentImports((prev) =>
        prev.filter((studentImport) => studentImport.id !== id)
      );
      ToastNotification("success", "Student import deleted successfully.");
    }
  };

  const csvParserOptions = {
    header: true,
    skipEmptyLines: true,
    transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
  };

  const handleCsvData = async (csv, fileInfo, originalFile) => {
    setLoading(true);

    // Create a FileReader to read the original file content
    const reader = new FileReader();

    reader.onload = async (event) => {
      const originalContent = event.target.result;

      const res = await createStudentImportRequest({
        original_csv: originalContent, // Send the original file content
      });

      const data = res.data.student_import.parsed_csv;
      setStudentImport(res.data.student_import);

      if (data.length > 0) {
        setCsvPreviewModal({ open: true });
        getStudentImports();
        setLoading(false);
      } else {
        ToastNotification(
          "error",
          "There are no students in the CSV to import."
        );
        setLoading(false);
      }
    };

    reader.readAsText(originalFile); // Read the file as text
  };

  useEffect(() => {
    getStudentImports();
  }, []);

  if (loading)
    return (
      <div className="p-6 text-center">
        <PageLoader />
      </div>
    );

  return (
    <>
      {studentImports?.length ? (
        <table className="w-[600px] m-auto mb-4 [&_th]:text-left">
          <thead>
            <tr>
              <th>Date</th>
              <th>Uploaded by</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {studentImports.map((studentImport) => (
              <tr key={studentImport.id}>
                <td>
                  {Intl.DateTimeFormat("en-US", {
                    dateStyle: "short",
                    timeStyle: "short",
                  }).format(new Date(studentImport.created_at))}
                </td>
                <td>
                  {studentImport.user.first_name} {studentImport.user.last_name}
                </td>
                <td>{studentImport.imported ? "Imported" : "Preview"}</td>
                <td>
                  <button
                    className="btn btn-primary mr-2 text-secondary text-sm hover:underline"
                    onClick={() => {
                      fetchStudentImport(studentImport.id);
                    }}
                  >
                    View
                  </button>
                  {!studentImport.imported && (
                    <button
                      className="btn btn-primary mr-2 text-secondary text-sm hover:underline"
                      onClick={() => {
                        deleteStudentImport(studentImport.id);
                      }}
                    >
                      Delete
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : !studentImports ? (
        <div className="text-center mb-4">Loading...</div>
      ) : (
        <div className="text-center mb-4">No student imports found</div>
      )}

      <div className="justify-center flex relative">
        <label
          className="d-flex justify-content-center align-items-center btn flex flex-col
            items-center justify-center p-4 border-dashed border border-black rounded-md mb-4 cursor-pointer"
        >
          <ArrowUpOnSquareIcon className="mr-2 w-5" />
          Click or drag students CSV to preview
          <CSVReader
            inputId="csv-reader-input"
            inputStyle={{
              cursor: "pointer",
              opacity: 0,
              position: "absolute",
              left: 0,
              top: 210,
              height: "100%",
              width: "100%",
            }}
            onFileLoaded={handleCsvData}
            parserOptions={csvParserOptions}
          />
        </label>
      </div>

      <CsvModal
        open={csvPreviewModal.open}
        setOpen={() => setCsvPreviewModal({ open: false })}
      >
        <CsvStudents
          closeModal={() => {
            setCsvPreviewModal({ open: false });
            document.getElementById("csv-reader-input").value = null;
            setTimeout(() => setShowCsvUpload(false), 500);
          }}
          studentImport={studentImport}
          setStudentImport={setStudentImport}
          setRefreshStudents={setRefreshStudents}
        />
      </CsvModal>
    </>
  );
}
