import React, { useEffect, useState } from "react";
import Select from "../../../UI/Inputs/Select/Select";
import SelectItem from "../../../UI/Inputs/Select/SelectItem";
import Input from "../../../UI/Inputs/Input";
import InputGroup from "../../../UI/Inputs/InputGroup";
import { getAllCountries } from "../../../../requests/countries";
import { getStatesByCountry } from "../../../../requests/states";
import { useFormContext } from "react-hook-form";
import { ToastNotification } from "../../../UI/ToastNotification";

export default function ContactInformationForm({ instructor }) {
  const { watch } = useFormContext();
  const [states, setStates] = useState([]);
  const [countries, setCountries] = useState([]);
  const selectedCountry = watch("country", instructor?.country);

  const getCountries = async () => {
    try {
      const res = await getAllCountries();

      if (res.data) {
        setCountries(res.data);
      }
    } catch (e) {
      ToastNotification("error", "Error fetching countries.");
    }
  };

  const getStates = async () => {
    try {
      const countryId = countries.find(
        (country) => country.name === selectedCountry
      )?.id;
      const res = await getStatesByCountry({
        countryId: countryId || countries[0]?.id,
      });

      if (res.data) {
        setStates(res.data);
      }
    } catch (e) {
      ToastNotification("error", "Error fetching states.");
    }
  };

  useEffect(() => {
    getCountries();
  }, []);

  useEffect(() => {
    if (countries.length > 0) {
      getStates();
    }
  }, [selectedCountry, countries]);

  return (
    <div>
      <h2 className="mb-6 text-2xl">Contact Information</h2>

      <InputGroup>
        <Input
          id="first_name"
          defaultValue={instructor?.first_name}
          classNames="mr-4"
          placeholder="First name"
          isRequired
        />
        <Input
          id="last_name"
          defaultValue={instructor?.last_name}
          placeholder="Last name"
          isRequired
        />
      </InputGroup>

      <InputGroup>
        <div className=" md:w-3/5">
          {countries.length > 0 && (
            <Select id="country" defaultValue={instructor?.country || ""}>
              <SelectItem value="" disabled>
                Select a country
              </SelectItem>
              {countries.map((country) => (
                <SelectItem key={country.id} value={country.name}>
                  {country.name}
                </SelectItem>
              ))}
            </Select>
          )}
        </div>
      </InputGroup>

      <InputGroup col>
        <Input
          id="address"
          defaultValue={instructor?.address}
          classNames=""
          placeholder="Address 1"
          label="Address"
        />
        <Input
          id="address_2"
          defaultValue={instructor?.address_2}
          placeholder="Address 2"
        />
      </InputGroup>

      <InputGroup>
        <Input id="city" defaultValue={instructor?.city} placeholder="City" />

        {states.length > 0 && (
          <Select id="state" label="State" defaultValue={instructor?.state}>
            <SelectItem value="" disabled>
              {watch("country") ? "Select a state" : "Select a country"}
            </SelectItem>
            {watch("country") &&
              states.map((state) => (
                <SelectItem key={state.id} value={state.abbreviation}>
                  {state.name}
                </SelectItem>
              ))}
          </Select>
        )}

        <Input
          id="zip_code"
          defaultValue={instructor?.zip_code}
          placeholder="Zip/Postal"
        />
      </InputGroup>

      <InputGroup>
        <Input
          id="phone"
          defaultValue={instructor?.phone}
          placeholder="Phone"
        />
        <Input
          defaultValue={instructor?.email}
          id="email"
          placeholder="Email"
          isRequired={!instructor}
          disabled={Boolean(instructor)}
        />
      </InputGroup>
    </div>
  );
}
