import axiosClient from "../utils/axios";
import { downloadCsvRequest } from "../utils/downloadCsvRequest";
import { baseEndpoint } from "./baseEndpoint";

const endpoint = `${baseEndpoint}/users`;

const getAllUsers = async (params) => {
  const data = await axiosClient.get(endpoint, params);

  return data;
};

const getAllUsersBySchoolRequest = async (params) => {
  const data = await axiosClient.get(`${endpoint}/by_schools`, {
    params: params,
  });

  return data;
};

const getAllUsersForShareRequest = async () => {
  const data = await axiosClient.get(`${endpoint}/share`);

  return data;
};

const getUserRequest = async (params) => {
  const data = await axiosClient.get(`${endpoint}/${params.userId}`);

  return data;
};

const updateUserRequest = async (params) => {
  const data = await axiosClient.put(`${endpoint}/${params.userId}`, params);

  return data;
};

const updateCurrentUserRequest = async (params) => {
  const data = await axiosClient.patch(`${endpoint}/update_current`, params);

  return data;
};

const updateCurrentUserImagesRequest = async (formData) => {
  const data = await axiosClient.patch(`${endpoint}/update_current`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return data;
};

const deleteUserRequest = async (params) => {
  const data = await axiosClient.delete(`${endpoint}/${params.userId}`);

  return data;
};

const getAllUserStudentsRequest = async (params) => {
  const data = await axiosClient.get(`${endpoint}/${params.userId}/students`, {
    params: params.params,
  });

  return data;
};

const getAllUserCoursesRequest = async (params) => {
  const data = await axiosClient.get(`${endpoint}/${params.userId}/courses`);

  return data;
};

const downloadCsvUsersRequest = async (params) =>
  downloadCsvRequest(`${endpoint}.csv`, params, params.params?.type || "User");

const welcomeEmailUserRequest = async (params) => {
  const data = await axiosClient.post(
    `${endpoint}/${params.userId}/welcome_email`
  );

  return data;
};

const deleteMFAUserRequest = async (params) => {
  const data = await axiosClient.delete(
    `${endpoint}/${params.userId}/delete_mfa`
  );

  return data;
};

export {
  getAllUsers,
  updateUserRequest,
  updateCurrentUserRequest,
  updateCurrentUserImagesRequest,
  getUserRequest,
  deleteUserRequest,
  getAllUserStudentsRequest,
  getAllUserCoursesRequest,
  getAllUsersBySchoolRequest,
  getAllUsersForShareRequest,
  downloadCsvUsersRequest,
  welcomeEmailUserRequest,
  deleteMFAUserRequest,
};
