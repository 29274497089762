import React, { useEffect, useState } from "react";
import Select from "../../../UI/Inputs/Select/Select";
import SelectItem from "../../../UI/Inputs/Select/SelectItem";
import InputGroup from "../../../UI/Inputs/InputGroup";
import Input from "../../../UI/Inputs/Input";
import { Tooltip } from "../../../UI/Tooltip";
import {
  getAllSchoolSeminars,
  getAllSchoolCourses,
} from "../../../../requests/schools";

export default function EnrollmentForm({ school, methods }) {
  const selectedSeriesId = methods.watch("series_id");
  const [seminars, setSeminars] = useState();
  const [courses, setCourses] = useState();

  const selectedSeries =
    seminars?.find((s) => s.id === selectedSeriesId) ||
    courses?.find((c) => c.id === selectedSeriesId);

  const getSeminars = async () => {
    const res = await getAllSchoolSeminars({ school_id: school.id });
    setSeminars(res.data);
  };

  const getCourses = async () => {
    const res = await getAllSchoolCourses({ school_id: school.id });
    setCourses(res.data);
  };

  useEffect(() => {
    getSeminars();
    getCourses();
  }, []);

  return (
    <div className="mb-6">
      <h2 className="mb-2 text-2xl">Enroll student in</h2>

      <div className="md:flex gap-4">
        <InputGroup classNames="md:w-1/2">
          <Select
            id="series_id"
            classNames="w-full"
            label="Course or Seminar"
            isRequired
            disabled={!courses || !seminars}
          >
            <SelectItem value="">-- Select --</SelectItem>
            {courses?.length > 0 && (
              <optgroup label="Courses">
                {courses?.map((course) => (
                  <SelectItem key={course.id} value={course.id}>
                    {course.name}
                  </SelectItem>
                ))}
              </optgroup>
            )}
            {seminars?.length > 0 && (
              <optgroup label="Seminars">
                {seminars?.map((seminar) => (
                  <SelectItem key={seminar.id} value={seminar.id}>
                    {seminar.name}
                  </SelectItem>
                ))}
              </optgroup>
            )}
          </Select>
        </InputGroup>
        <InputGroup classNames="hidden md:flex md:w-1/2">
          <Tooltip
            wrap
            content="
            If the course you are looking for is missing, please contact VOP so they can register
            your school to offer that course. To contact VOP, email discoverschool@vop.com
            or phone (970) 622-2990."
          >
            <button type="button" className="text-sm text-gray-500 mb-2">
              Missing a course?
            </button>
          </Tooltip>
        </InputGroup>
      </div>

      {selectedSeries?.series_type === "Seminar" && (
        <div className="md:flex gap-4">
          <InputGroup classNames="md:w-1/2">
            <Select
              id="presentation"
              classNames="w-full"
              label="Presentation"
              disabled={!selectedSeries?.presentations.length}
            >
              <SelectItem value="" selected>
                - No attendance
              </SelectItem>
              {selectedSeries?.presentations.map((presentation) => (
                <SelectItem key={presentation.id} value={presentation.id}>
                  {Intl.DateTimeFormat("en-US").format(
                    new Date(presentation.date)
                  )}{" "}
                  - {presentation.name}
                </SelectItem>
              ))}
            </Select>
          </InputGroup>

          <InputGroup classNames="md:w-1/2">
            <Input
              label="Registration number"
              id="registration_number"
              type="text"
              placeholder="Registration number"
            />
          </InputGroup>
        </div>
      )}

      <Input
        id="interest_card"
        type="file"
        inputClassNames="border-none"
        label="Interest Card Scan"
      />
    </div>
  );
}
