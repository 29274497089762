import React, { useCallback } from "react";
import Filter from "../UI/Filter/Filter";
import { getAllUsers } from "../../requests/users";
import { getAllCourses } from "../../requests/courses";
import { getAllSeminars } from "../../requests/seminars";
import { getAllSchools } from "../../requests/schools";
import { getAllStudents } from "../../requests/students";

export default function VersionsFilter() {
  const getActors = useCallback(async () => {
    try {
      const res = await getAllUsers({
        params: { pagination: false },
      });
      return res.data.users.map((user) => ({
        label: `${user.first_name} ${user.last_name} (${user.email})`,
        value: user.id,
      }));
    } catch (error) {
      return [];
    }
  }, []);

  const getStudents = useCallback(async () => {
    try {
      const res = await getAllStudents({
        params: { pagination: false },
      });
      return res.data.students.map((student) => ({
        label: `${student.first_name} ${student.last_name} (${student.email})`,
        value: student.id,
      }));
    } catch (error) {
      return [];
    }
  }, []);

  const getInstructors = useCallback(async () => {
    try {
      const res = await getAllUsers({
        params: { pagination: false },
      });
      return res.data.users.map((user) => ({
        label: `${user.first_name} ${user.last_name} (${user.email})`,
        value: user.id,
      }));
    } catch (error) {
      return [];
    }
  }, []);

  const getCourses = useCallback(async () => {
    try {
      const res = await getAllCourses({
        params: { pagination: false },
      });
      return res.data.courses.map((course) => ({
        label: course.name,
        value: course.id,
      }));
    } catch (error) {
      return [];
    }
  }, []);

  const getSeminars = useCallback(async () => {
    try {
      const res = await getAllSeminars({
        params: { pagination: false },
      });
      return res.data.seminars.map((seminar) => ({
        label: seminar.name,
        value: seminar.id,
      }));
    } catch (error) {
      return [];
    }
  }, []);

  const getSchools = useCallback(async () => {
    try {
      const res = await getAllSchools({
        params: { pagination: false },
      });
      return res.data.schools.map((school) => ({
        label: `(#${school.school_code}) ${school.school_name}`,
        value: school.id,
      }));
    } catch (error) {
      return [];
    }
  }, []);

  const filterAttributes = {
    event: {
      label: "Event",
      operators: ["eq", "not_eq", "null", "not_null"],
      defaultOperator: "eq",
      options: [
        { label: "Create", value: "create" },
        { label: "Update", value: "update" },
        { label: "Destroy", value: "destroy" },
      ],
    },
    item_id: {
      label: "Record ID",
      operators: ["eq", "not_eq", "null", "not_null"],
      defaultOperator: "eq",
    },
    whodunnit: {
      label: "Actor",
      operators: ["eq", "not_eq", "null", "not_null"],
      defaultOperator: "eq",
      options: getActors,
      autocomplete: true,
    },
    school_id: {
      label: "School",
      operators: ["eq", "not_eq", "null", "not_null"],
      defaultOperator: "eq",
      options: getSchools,
      autocomplete: true,
    },
    instructor_id: {
      label: "Instructor",
      operators: ["eq", "not_eq", "null", "not_null"],
      defaultOperator: "eq",
      options: getInstructors,
      autocomplete: true,
    },
    student_id: {
      label: "Student",
      operators: ["eq", "not_eq", "null", "not_null"],
      defaultOperator: "eq",
      options: getStudents,
      autocomplete: true,
    },
    course_id: {
      label: "Course",
      operators: [
        { label: "equal", value: "eq" },
        { label: "any course", value: "not_null" },
      ],
      defaultOperator: "eq",
      options: getCourses,
      autocomplete: true,
    },
    seminar_id: {
      label: "Seminar",
      operators: [
        { label: "equal", value: "eq" },
        { label: "any seminar", value: "not_null" },
      ],
      defaultOperator: "eq",
      options: getSeminars,
      autocomplete: true,
    },
    created_at_date: {
      label: "Created at",
      type: "date",
      defaultOperator: "between",
      excludeOperators: ["null", "not_null"],
    },
  };

  return <Filter attributes={filterAttributes} />;
}
