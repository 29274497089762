import React, { useState, Fragment, useEffect } from "react";
import {
  Outlet,
  useLoaderData,
  useOutletContext,
  useLocation,
  Link,
} from "react-router-dom";
import { fullName } from "../../../utils/fullName";
import { useMediaQuery } from "../../../utils/useMediaQuery";
import { useMobileNavigation } from "../../../contexts/MobileNavigationContext";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { Transition } from "@headlessui/react";
import { twMerge } from "tailwind-merge";
import StudentNav from "./StudentNav";

export default function Student() {
  const isMobile = useMediaQuery("md", "down");
  const { user } = useOutletContext();
  const { setMobileNavigation } = useMobileNavigation();
  const student = useLoaderData().data;
  const [navOpen, setNavOpen] = useState(true);
  const location = useLocation();

  const shrinkedSchoolName = () => {
    if (student?.school?.church_name.length > 20) {
      return student?.school?.church_name.slice(0, 20) + "...";
    }
    return student?.school?.church_name;
  };

  // No header on these pages
  const noHeader = [
    "/lessons",
    "/presentations",
    "/decisions",
    "/notes",
    "/products",
    "/reminders",
    "/visits",
    "/management",
  ].some((path) => location.pathname.includes(path));

  useEffect(() => {
    if (student) {
      setMobileNavigation({
        title: fullName(student?.first_name, " ", student?.last_name),
        links: [
          {
            name: "Overview",
            href: `/students/${student.id}/overview`,
            show: true, // TODO: Add permission check
          },
          {
            name: "Profile",
            href: `/students/${student.id}/profile`,
            show: true, // TODO: Add permission check
          },
          {
            name: "Courses",
            href: `/students/${student.id}/courses`,
            show: true, // TODO: Add permission check
          },
          {
            name: "Seminars",
            href: `/students/${student.id}/seminars`,
            show: true, // TODO: Add permission check
          },
          {
            name: "Decisions",
            href: `/students/${student.id}/decisions`,
            show: true, // TODO: Add permission check
          },
          {
            name: "Notes",
            href: `/students/${student.id}/notes`,
            show: true, // TODO: Add permission check
          },
          {
            name: "Products",
            href: `/students/${student.id}/products`,
            show: true, // TODO: Add permission check
          },
        ],
      });
    }

    return () => {
      setMobileNavigation(null);
    };
  }, [student]);

  return (
    <div>
      {(!isMobile || !noHeader) && (
        <div
          className={twMerge(
            !navOpen && "mb-7",
            "md:mb-0 mb-4 flex items-center justify-center text-center"
          )}
        >
          <h1 className="md:block hidden text-2xl">
            {fullName(student?.first_name, " ", student?.last_name)}
            {" | "}
            <Link
              to={`/schools/${student?.school?.id}/overview`}
              className="text-secondary-500 hover:text-secondary-600"
            >
              {shrinkedSchoolName()}
            </Link>
          </h1>

          <div className="md:hidden block">
            <h1 className="text-2xl text-[40px] mb-2 tracking-tight">
              {fullName(student?.first_name, " ", student?.last_name)}
            </h1>
            <p>
              <Link
                to={`/schools/${student?.school?.id}`}
                className="text-secondary-500 hover:text-secondary-600"
              >
                {shrinkedSchoolName()}
              </Link>
            </p>
          </div>

          <EllipsisVerticalIcon
            className="md:block hidden ml-5 w-6 cursor-pointer"
            onClick={() => setNavOpen(!navOpen)}
          />
        </div>
      )}

      <Transition.Root show={navOpen} as={Fragment}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="md:block hidden">
            <StudentNav student={student} />
          </div>
        </Transition.Child>
      </Transition.Root>

      <Outlet context={{ user, student }} />
    </div>
  );
}
