import React, { useEffect } from "react";
import TableCell from "../../UI/Table/TableCell";
import Checkbox from "../../UI/Inputs/Checkbox";

export default function CsvReadOnlyRow({
  index,
  handleRemovedRows,
  removedRows,
  onClick,
  methods,
  importSchoolInstructors,
  selectedSchool,
  student,
}) {
  const { watch } = methods;
  const instructors = importSchoolInstructors;

  const handleInstructorChange = async (value) => {
    let instructor = instructors.find((i) => i.id === value);

    if (instructor) {
      methods.setValue(`csv_students.${index}.instructor_id`, value);

      methods.setValue(
        `csv_students.${index}.instructor_name`,
        instructor.first_name + " " + instructor.last_name
      );
    }
  };

  useEffect(() => {
    methods.setValue(`csv_students.${index}.index`, index);
  }, []);

  // There are 3 cases to pre-select the Instructor while changing School:
  // 1. The instructor is the selected one directly in dropdown;
  // 2. The instructor is the CSV column match;
  // 3. The instructor is the head school admin;
  useEffect(() => {
    if (instructors?.length) {
      const currentInstructorId = watch(`csv_students.${index}.instructor_id`);

      if (instructors.find((i) => i.id === currentInstructorId)) {
        handleInstructorChange(currentInstructorId);
      } else if (instructors.find((i) => i.id === student.instructor_id)) {
        handleInstructorChange(student.instructor_id);
      } else if (selectedSchool?.head_school_admin_id) {
        handleInstructorChange(selectedSchool.head_school_admin_id);
      } else {
        methods.setValue(`csv_students.${index}.instructor_id`, "");
        methods.setValue(`csv_students.${index}.instructor_name`, "");
      }
    } else if (instructors) {
      methods.setValue(`csv_students.${index}.instructor_id`, "");
      methods.setValue(`csv_students.${index}.instructor_name`, "");
    }
  }, [instructors, selectedSchool]);

  return (
    <tr
      key={index}
      id={`readonly-row-${index}`}
      className="[&_td]:px-4 [&_td]:h-[63px]"
    >
      <TableCell classNames="flex items-center gap-2">
        {index + 1}.
        <Checkbox
          id={`checkbox-${index}`}
          defaultChecked={!removedRows.includes(index)}
          onClick={(event) => {
            event.stopPropagation();
            handleRemovedRows(event, index);
          }}
        />
      </TableCell>
      <TableCell onClick={() => onClick("title")}>
        {watch(`csv_students.${index}.title`) || "-"}
      </TableCell>
      <TableCell onClick={() => onClick("first_name")}>
        {watch(`csv_students.${index}.first_name`) || "-"}
      </TableCell>
      <TableCell onClick={() => onClick("middle_name")}>
        {watch(`csv_students.${index}.middle_name`) || "-"}
      </TableCell>
      <TableCell onClick={() => onClick("last_name")}>
        {watch(`csv_students.${index}.last_name`) || "-"}
      </TableCell>
      <TableCell onClick={() => onClick("school_id")}>
        {!watch("school_id") ? (
          <span>
            (#{watch(`csv_students.${index}.school_code`)}){" "}
            {watch(`csv_students.${index}.school_name`)}
          </span>
        ) : (
          <span>-- Selected --</span>
        )}
      </TableCell>
      <TableCell onClick={() => onClick("instructor_id")}>
        {watch(`csv_students.${index}.instructor_name`) || "-"}
        <span className="text-xs text-gray-500">
          {watch(`csv_students.${index}.instructor_id`) ===
            selectedSchool?.head_school_admin_id && " (Head School Admin)"}
        </span>
      </TableCell>
      <TableCell onClick={() => onClick("series_id")}>
        {!watch("import_series_id") ? (
          <span>{watch(`csv_students.${index}.series_name`) || "-"}</span>
        ) : (
          <span>-- Selected --</span>
        )}
      </TableCell>
      <TableCell onClick={() => onClick("registration_number")}>
        {watch(`csv_students.${index}.registration_number`) || "-"}
      </TableCell>
      <TableCell onClick={() => onClick("referral")}>
        {watch(`csv_students.${index}.referral`) || "-"}
      </TableCell>
      <TableCell onClick={() => onClick("address")}>
        {watch(`csv_students.${index}.address`) || "-"}
      </TableCell>
      <TableCell onClick={() => onClick("address_2")}>
        {watch(`csv_students.${index}.address_2`) || "-"}
      </TableCell>
      <TableCell onClick={() => onClick("city")}>
        {watch(`csv_students.${index}.city`) || "-"}
      </TableCell>
      <TableCell onClick={() => onClick("state")}>
        {watch(`csv_students.${index}.state`) || "-"}
      </TableCell>
      <TableCell onClick={() => onClick("zip_code")}>
        {watch(`csv_students.${index}.zip_code`) || "-"}
      </TableCell>
      <TableCell onClick={() => onClick("country")}>
        {watch(`csv_students.${index}.country`) || "-"}
      </TableCell>
      <TableCell onClick={() => onClick("gender")}>
        {watch(`csv_students.${index}.gender`) || "-"}
      </TableCell>
      <TableCell classNames="text-center" onClick={() => onClick("married")}>
        {watch(`csv_students.${index}.married`) ? "Yes" : "No"}
      </TableCell>
      <TableCell onClick={() => onClick("birthdate")}>
        {watch(`csv_students.${index}.birthdate`) &&
          Intl.DateTimeFormat("en-US", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          }).format(new Date(watch(`csv_students.${index}.birthdate`)))}
      </TableCell>
      <TableCell onClick={() => onClick("denomination")}>
        {watch(`csv_students.${index}.denomination`) || "-"}
      </TableCell>
      <TableCell classNames="text-center" onClick={() => onClick("baptized")}>
        {watch(`csv_students.${index}.baptized`) ? "Yes" : "No"}
      </TableCell>
      <TableCell onClick={() => onClick("phone")}>
        {watch(`csv_students.${index}.phone`) || "-"}
      </TableCell>
      <TableCell
        classNames="text-center"
        onClick={() => onClick("phone_is_sms")}
      >
        {watch(`csv_students.${index}.phone_is_sms`) ? "Yes" : "No"}
      </TableCell>
      <TableCell onClick={() => onClick("phone_2")}>
        {watch(`csv_students.${index}.phone_2`) || "-"}
      </TableCell>
      <TableCell
        classNames="text-center"
        onClick={() => onClick("phone_2_is_sms")}
      >
        {watch(`csv_students.${index}.phone_2_is_sms`) ? "Yes" : "No"}
      </TableCell>
      <TableCell onClick={() => onClick("email")}>
        {watch(`csv_students.${index}.email`) || "-"}
      </TableCell>
      <TableCell onClick={() => onClick("social_media")}>
        {watch(`csv_students.${index}.social_media`) || "-"}
      </TableCell>
    </tr>
  );
}
