import React, { useEffect, useState } from "react";
import { useMediaQuery } from "../../../../utils/useMediaQuery";
import Card from "../../../UI/Card";
import Table from "../../../UI/Table/Table";
import TableHeader from "../../../UI/Table/TableHeader";
import TableCell from "../../../UI/Table/TableCell";
import EmptyTable from "../../../UI/EmptyTable";
import { checkPermission } from "../../../../utils/checkPermission";
import { Link, useNavigate, useRouteLoaderData } from "react-router-dom";
import { getAllSchoolInstructors } from "../../../../requests/schools";
import { renderAddress } from "../../../../utils/fullAddress";
import Pill from "../../../UI/Pill";
import PageLoader from "../../../UI/PageLoader";
import { hoverLink } from "../../../../utils/hoverLink";
import { fullName } from "../../../../utils/fullName";
import { displayError } from "../../../../utils/displayError";
import SchoolInstructorsMobile from "./Mobile/SchoolInstructorsMobile";
import EditOnHover from "../../../UI/EditOnHover";

export default function SchoolInstructors() {
  const isMobile = useMediaQuery("md", "down");
  const school = useRouteLoaderData("school").data;
  const navigate = useNavigate();
  const [instructors, setInstructors] = useState();

  const getSchoolInstructors = async () => {
    try {
      const res = await getAllSchoolInstructors({ school_id: school.id });

      if (res.data) {
        setInstructors(res.data);
      }
    } catch (e) {
      displayError(e);
    }
  };

  useEffect(() => {
    getSchoolInstructors();
  }, []);

  return isMobile ? (
    <SchoolInstructorsMobile instructors={instructors} />
  ) : instructors ? (
    <div>
      <Card>
        <div className="flex items-center justify-between mb-6">
          <div className="relative">
            {checkPermission(["school_admin", "conference_admin"]) && (
              <img
                src="/add-new.svg"
                className="absolute left-0 top-1/2 mt-0 h-auto max-w-full -translate-x-10 -translate-y-1/2 transform cursor-pointer"
                width={30}
                height={30}
                alt="Add New"
                onClick={() =>
                  navigate(
                    `/instructors/new?school_id=${school.id}&role=instructor`
                  )
                }
              />
            )}

            <h1 className="text-xl font-bold text-primary">INSTRUCTORS</h1>
          </div>
        </div>
        {instructors?.length ? (
          <Table>
            <thead>
              <tr>
                <TableHeader>NAME</TableHeader>
                <TableHeader>STUDENTS</TableHeader>
                <TableHeader>CONTACT INFO</TableHeader>
                <TableHeader>ADDRESS</TableHeader>
                <TableHeader>ROLES</TableHeader>
              </tr>
            </thead>
            <tbody>
              {instructors.map((instructor) => (
                <tr key={instructor.id}>
                  <TableCell>
                    <EditOnHover to={`/instructors/${instructor.id}/profile`}>
                      <Link
                        className={`${hoverLink}`}
                        to={`/instructors/${instructor.id}/overview`}
                      >
                        {fullName(
                          instructor.first_name,
                          "",
                          instructor.last_name
                        )}
                      </Link>
                    </EditOnHover>
                  </TableCell>
                  <TableCell>
                    <Link
                      className={hoverLink}
                      to={`/instructors/${instructor.id}/students`}
                    >
                      {instructor.students_count}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <p>{instructor.email}</p>
                    <p>{instructor.phone}</p>
                  </TableCell>
                  <TableCell>
                    {renderAddress([
                      instructor.address,
                      instructor.address_2,
                      instructor.city,
                      instructor.state,
                      instructor.zip_code,
                      instructor.country,
                    ])}
                  </TableCell>
                  <TableCell>
                    {instructor.head_school_admin_for_schools?.map((school) => (
                      <div key={school.id}>
                        <Pill>Head School Admin</Pill> at{" "}
                        <Link
                          className={hoverLink}
                          to={`/schools/${school.id}/overview`}
                        >
                          {school.church_name}
                        </Link>
                      </div>
                    ))}
                    {instructor.roles.map((role) => (
                      <div key={role.id}>
                        <Pill>{role.titleized_role}</Pill> at{" "}
                        {role.organization_name}
                      </div>
                    ))}
                  </TableCell>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <EmptyTable message="No instructors found." />
        )}
      </Card>
    </div>
  ) : (
    <Card>
      <PageLoader variant="primary" />
    </Card>
  );
}
